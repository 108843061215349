@import 'style/_variables.scss';

.splash {
  display: grid;
  justify-items: center;
  align-items: end;
  &_jar {
    margin: 15vh 2.6rem 0 0;
    width: auto;
    height: 8.2rem;
  }

  &_gradientText {
    font-size: 4.6rem;
    font-weight: $font-bold;
    background: linear-gradient(118deg, #2B0096, #E9649B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 4rem;
  }

  &_introText {
    font-size: 1.6rem;
    font-weight: $font-regular;
    margin-top: 2.5rem;
    text-align: center;
    line-height: 1.3;
    width: 25rem;
  }
}
