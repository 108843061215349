@import 'style/main.scss';

.balance {

  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_title {
    font-size: 2.4rem;
    margin-top: 0.9rem;
  }

  &_subTitle {
    margin-top: .5rem;
    font-size: 1.4rem;
  }

  &_tip {
    display: grid;
    text-align: center;
  }

  &_infobox {
    margin-top: 3rem;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 2rem;

    > button {
      border: none;
    }
  }

  &_editProfile {
    display: block;
    text-align: left;
    margin: 2rem auto 0 3rem;
    font-size: 1.6rem;
    color: $text-grey;
  }

  &_thankYouAddText {
    margin-top: 0;
    font-size: 1.6rem;
  }
}