@import 'style/main.scss';

.tipBoxInvite {
  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_header {
    font-size: 2.4rem;
    font-weight: $font-bold;
    text-align: center;
    margin: 4rem 3rem;
    line-height: 1.4;
  }

  &_spinner {
    display: grid;
    margin-top: 2rem;
  }

}