@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
/* Global variables */
.container {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.title {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .title_log {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .title__small {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .title__smallBold {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .title__marginTitle {
    margin: 2.2rem 0 1rem; }
  .title__err {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.inputValue {
  font-weight: 700; }
  .inputValue__int {
    font-size: 3rem; }
  .inputValue__cent {
    font-size: 2.2rem; }
  .inputValue__currency {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.mt-5 {
  margin-top: .5rem; }

.mt-10 {
  margin-top: 1rem; }

.mt-15 {
  margin-top: 1.5rem; }

.mt-20 {
  margin-top: 2rem; }

.mt-25 {
  margin-top: 2.5rem; }

.mt-30 {
  margin-top: 3rem; }

.mb-5 {
  margin-bottom: .5rem; }

.mb-10 {
  margin-bottom: 1rem; }

.mb-15 {
  margin-bottom: 1.5rem; }

.mb-20 {
  margin-bottom: 2rem; }

.mb-25 {
  margin-bottom: 2.5rem; }

.mb-30 {
  margin-bottom: 3rem; }

.ml-5 {
  margin-left: .5rem; }

.ml-10 {
  margin-left: 1rem; }

.ml-15 {
  margin-left: 1.5rem; }

.ml-20 {
  margin-left: 2rem; }

.ml-25 {
  margin-left: 2.5rem; }

.ml-30 {
  margin-left: 3rem; }

.mr-5 {
  margin-right: .5rem; }

.mr-10 {
  margin-right: 1rem; }

.mr-15 {
  margin-right: 1.5rem; }

.mr-20 {
  margin-right: 2rem; }

.mr-25 {
  margin-right: 2.5rem; }

.mr-30 {
  margin-right: 3rem; }

.pt-5 {
  padding-top: .5rem; }

.pt-10 {
  padding-top: 1rem; }

.pt-15 {
  padding-top: 1.5rem; }

.pt-20 {
  padding-top: 2rem; }

.pt-25 {
  padding-top: 2.5rem; }

.pt-30 {
  padding-top: 3rem; }

.pb-5 {
  padding-bottom: .5rem; }

.pb-10 {
  padding-bottom: 1rem; }

.pb-15 {
  padding-bottom: 1.5rem; }

.pb-20 {
  padding-bottom: 2rem; }

.pb-25 {
  padding-bottom: 2.5rem; }

.pb-30 {
  padding-bottom: 3rem; }

.pl-5 {
  padding-left: .5rem; }

.pl-10 {
  padding-left: 1rem; }

.pl-15 {
  padding-left: 1.5rem; }

.pl-20 {
  padding-left: 2rem; }

.pl-25 {
  padding-left: 2.5rem; }

.pl-30 {
  padding-left: 3rem; }

.pr-5 {
  padding-right: .5rem; }

.pr-10 {
  padding-right: 1rem; }

.pr-15 {
  padding-right: 1.5rem; }

.pr-20 {
  padding-right: 2rem; }

.pr-25 {
  padding-right: 2.5rem; }

.pr-30 {
  padding-right: 3rem; }

html, body, #root {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.background_gradient {
  height: 100%; }
  .background_gradient__gray {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .background_gradient__purpure {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.card__active {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.card__activeRotate {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.card__disabled {
  border: 2px solid #CDD8EF !important; }
  .card__disabled > span {
    color: #CDD8EF !important;
    font-weight: 600; }

/* Global variables */
.App_switchWrapper__WQKJV {
  position: relative;
  height: calc(100% - 5rem); }

.App_switchWrapper__WQKJV > div {
  position: absolute;
  width: 100%;
  height: 100%; }

/* Global variables */
.SpinnerLarge_lds-spinner-large__1BOuq {
  justify-self: center;
  width: 80px;
  height: 80px; }

.SpinnerLarge_lds-spinner-large__1BOuq div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: SpinnerLarge_lds-spinner-large__1BOuq 1.2s linear infinite;
          animation: SpinnerLarge_lds-spinner-large__1BOuq 1.2s linear infinite; }

.SpinnerLarge_lds-spinner-large__1BOuq div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 40px;
  width: 4px;
  height: 25px;
  border-radius: 25%;
  background: -webkit-gradient(linear, left top, left bottom, from(#E9649B), to(#2B0096));
  background: linear-gradient(#E9649B, #2B0096); }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

.SpinnerLarge_lds-spinner-large__1BOuq div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

@-webkit-keyframes SpinnerLarge_lds-spinner-large__1BOuq {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes SpinnerLarge_lds-spinner-large__1BOuq {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* Global variables */
.Loading_loading__3-t_e {
  position: fixed;
  display: grid;
  justify-items: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.9; }

.Notification_notification_msgWrapper__1CiU3 {
  display: grid;
  grid-template-columns: 3.3rem 1fr;
  align-items: center; }

/* Global variables */
.Intro_splash__1eDSI {
  display: grid;
  justify-items: center;
  align-items: end; }
  .Intro_splash_jar__3VWgp {
    margin: 15vh 2.6rem 0 0;
    width: auto;
    height: 8.2rem; }
  .Intro_splash_gradientText__EazUm {
    font-size: 4.6rem;
    font-weight: 700;
    background: linear-gradient(118deg, #2B0096, #E9649B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 4rem; }
  .Intro_splash_introText__2aKaw {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 2.5rem;
    text-align: center;
    line-height: 1.3;
    width: 25rem; }

/* Global variables */
.Login_login_wrapper__33Ba0 {
  color: white;
  position: relative;
  overflow: auto; }

.Login_login_form__1Wu0t {
  justify-items: center;
  align-items: start;
  grid-template-rows: repeat(6, -webkit-max-content) minmax(3.5rem, 6rem);
  grid-template-rows: repeat(6, max-content) minmax(3.5rem, 6rem); }
  .Login_login_form__1Wu0t input:-webkit-autofill,
  .Login_login_form__1Wu0t input -webkit-autofill:hover,
  .Login_login_form__1Wu0t input -webkit-autofill:focus,
  .Login_login_form__1Wu0t input -webkit-autofill:active {
    border: 2px solid white;
    border-radius: .6rem;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset; }

.Login_login_resetLink__1xefK {
  font-size: 1.4rem;
  color: white;
  justify-self: end; }

.Login_login_signupText__jfbAh, .Login_login_signupLink__3C-b_ {
  font-size: 1.4rem;
  line-height: 1.5;
  color: white;
  justify-self: start;
  align-self: end;
  text-decoration: none; }
  .Login_login_signupText__nowrap__3Kwu4, .Login_login_signupLink__nowrap__3IQYo {
    white-space: nowrap; }

.Login_login_signupLink__3C-b_ {
  text-decoration: underline;
  font-weight: 700;
  white-space: nowrap; }

.Login_login_facebookIcon__2K5Hf {
  position: relative;
  top: -1px;
  height: 1.7rem;
  color: white; }

/* Global variables */
.Logout_logout__1BiOu {
  display: grid;
  grid-template-rows: repeat(2, -webkit-max-content) auto;
  grid-template-rows: repeat(2, max-content) auto;
  justify-items: center;
  align-items: end; }
  .Logout_logout_jar__32LKv {
    margin: 15vh 1.5rem 0 0;
    width: auto;
    height: 82px; }
  .Logout_logout_title__8bPnu {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2.5rem 0 4rem 0;
    text-align: center; }
  .Logout_logout_logoutLink__37Btw {
    color: #404040;
    font-size: 1.6rem;
    align-self: start; }

/* Global variables */
.Reset_password_reset_wrapper__1opMC {
  color: white;
  position: relative;
  overflow: auto; }

.Reset_password_reset_form__M9PhM {
  justify-items: center;
  align-items: start;
  grid-template-rows: repeat(4, -webkit-max-content) minmax(4rem, auto);
  grid-template-rows: repeat(4, max-content) minmax(4rem, auto);
  height: calc(100% - 5rem); }
  .Reset_password_reset_form__M9PhM input:-webkit-autofill,
  .Reset_password_reset_form__M9PhM input -webkit-autofill:hover,
  .Reset_password_reset_form__M9PhM input -webkit-autofill:focus,
  .Reset_password_reset_form__M9PhM input -webkit-autofill:active {
    border: 2px solid white;
    border-radius: .6rem;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset; }

.Reset_password_reset_info__5BJoH {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1.5rem; }

.Reset_password_reset_loginLink__1wkJF {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
  justify-self: start;
  align-self: end;
  margin-bottom: 2rem; }

/* Global variables */
.Reset_password_finish_reset_wrapper__1EHae {
  color: white;
  position: relative; }

.Reset_password_finish_reset_container__8xXn3 {
  justify-items: center;
  align-items: start;
  height: calc(100% - 5rem);
  grid-template-rows: repeat(2, -webkit-max-content) auto;
  grid-template-rows: repeat(2, max-content) auto; }

.Reset_password_finish_reset_info__1AAHa {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1.5rem; }

.Reset_password_finish_reset_button__2NXXQ {
  align-self: center; }

/* Global variables */
.Terms_terms_termsTitle__2z5io {
  margin: 3rem 1.8rem;
  font-weight: 700;
  grid-area: title;
  font-size: 1.8rem;
  width: calc(83vw - 6rem); }

.Terms_terms_termsText__YiBat {
  margin: 0 2.5rem 2rem 2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  grid-area: terms; }
  .Terms_terms_termsText__YiBat :first-child {
    margin-top: 0; }
  .Terms_terms_termsText__YiBat p {
    margin-right: 3.5rem; }

/* Global variables */
.Sign_up_signUp_wrapper__3opO0 {
  color: white;
  position: relative;
  overflow: auto; }

.Sign_up_signUp_form__19IQN {
  justify-items: center;
  align-items: start;
  grid-template-rows: repeat(5, -webkit-max-content) minmax(2rem, 4.5rem);
  grid-template-rows: repeat(5, max-content) minmax(2rem, 4.5rem); }
  .Sign_up_signUp_form__19IQN input:-webkit-autofill,
  .Sign_up_signUp_form__19IQN input -webkit-autofill:hover,
  .Sign_up_signUp_form__19IQN input -webkit-autofill:focus,
  .Sign_up_signUp_form__19IQN input -webkit-autofill:active {
    border: 2px solid white;
    border-radius: .6rem;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset; }

.Sign_up_signUp_terms__oQ610 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
  font-size: 1.4rem;
  margin: 2rem 0 1rem 0; }

.Sign_up_signUp_termsLink__1oIRI {
  font-size: 1.4rem;
  color: white;
  justify-self: end;
  text-decoration: underline;
  cursor: pointer; }

.Sign_up_signUp_loginText__2InSn, .Sign_up_signUp_loginLink__27oFK {
  font-size: 1.4rem;
  color: white;
  justify-self: start;
  align-self: end;
  text-decoration: none; }

.Sign_up_signUp_loginLink__27oFK {
  text-decoration: underline;
  font-weight: 700; }

.Sign_up_signUp_termsTitle__2m3Pa {
  margin: 3rem 1.8rem;
  font-weight: 700;
  grid-area: title;
  font-size: 1.8rem;
  width: calc(83vw - 6rem); }

.Sign_up_signUp_termsText__30PQX {
  margin: 0 2.5rem 2rem 2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  grid-area: terms; }
  .Sign_up_signUp_termsText__30PQX :first-child {
    margin-top: 0; }
  .Sign_up_signUp_termsText__paragraph__3sh93 {
    margin-right: 3.5rem; }

/* Global variables */
.Sign_up_finish_signUp_wrapper__OKP1p {
  color: white;
  position: relative; }

.Sign_up_finish_signUp_container__1UKLC {
  justify-items: center;
  align-items: start;
  height: calc(100% - 5rem);
  grid-template-rows: repeat(2, -webkit-max-content) auto;
  grid-template-rows: repeat(2, max-content) auto; }

.Sign_up_finish_signUp_info__Xx8K9 {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1.5rem; }

.Sign_up_finish_signUp_button__--LQe {
  align-self: center; }

/* Global variables */
.New_tip_container__3GlB1 {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.New_tip_title__3xglo {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .New_tip_title_log__3YTp9 {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .New_tip_title__small__1iXwD {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .New_tip_title__smallBold__33-Se {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .New_tip_title__marginTitle__2bCDw {
    margin: 2.2rem 0 1rem; }
  .New_tip_title__err__25qfs {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.New_tip_inputValue__3B1VS {
  font-weight: 700; }
  .New_tip_inputValue__int__2nYaQ {
    font-size: 3rem; }
  .New_tip_inputValue__cent__2NteZ {
    font-size: 2.2rem; }
  .New_tip_inputValue__currency__3UPFP {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.New_tip_mt-5__BRPgG {
  margin-top: .5rem; }

.New_tip_mt-10__2Y2xJ {
  margin-top: 1rem; }

.New_tip_mt-15__gRBwU {
  margin-top: 1.5rem; }

.New_tip_mt-20__CgG4Q {
  margin-top: 2rem; }

.New_tip_mt-25__3r3TD {
  margin-top: 2.5rem; }

.New_tip_mt-30__1PZFg {
  margin-top: 3rem; }

.New_tip_mb-5__10qFT {
  margin-bottom: .5rem; }

.New_tip_mb-10__KuHaA {
  margin-bottom: 1rem; }

.New_tip_mb-15__2zNxg {
  margin-bottom: 1.5rem; }

.New_tip_mb-20__3LDSX {
  margin-bottom: 2rem; }

.New_tip_mb-25__3eII- {
  margin-bottom: 2.5rem; }

.New_tip_mb-30__3KZmQ {
  margin-bottom: 3rem; }

.New_tip_ml-5__3X0dh {
  margin-left: .5rem; }

.New_tip_ml-10__3CU6Y {
  margin-left: 1rem; }

.New_tip_ml-15__34GIC {
  margin-left: 1.5rem; }

.New_tip_ml-20__3gCGj {
  margin-left: 2rem; }

.New_tip_ml-25__1A0Io {
  margin-left: 2.5rem; }

.New_tip_ml-30__1t2di {
  margin-left: 3rem; }

.New_tip_mr-5__mWH-G {
  margin-right: .5rem; }

.New_tip_mr-10__20Cwc {
  margin-right: 1rem; }

.New_tip_mr-15__2TZpc {
  margin-right: 1.5rem; }

.New_tip_mr-20__2u-IO {
  margin-right: 2rem; }

.New_tip_mr-25__2xGBw {
  margin-right: 2.5rem; }

.New_tip_mr-30__18Hbl {
  margin-right: 3rem; }

.New_tip_pt-5__1-23G {
  padding-top: .5rem; }

.New_tip_pt-10__1eyzp {
  padding-top: 1rem; }

.New_tip_pt-15__1izNS {
  padding-top: 1.5rem; }

.New_tip_pt-20__2rIvG {
  padding-top: 2rem; }

.New_tip_pt-25__irYVT {
  padding-top: 2.5rem; }

.New_tip_pt-30__5IDjc {
  padding-top: 3rem; }

.New_tip_pb-5__1UMtF {
  padding-bottom: .5rem; }

.New_tip_pb-10__3FhpD {
  padding-bottom: 1rem; }

.New_tip_pb-15__8RYcD {
  padding-bottom: 1.5rem; }

.New_tip_pb-20__2Ogyh {
  padding-bottom: 2rem; }

.New_tip_pb-25__14sA2 {
  padding-bottom: 2.5rem; }

.New_tip_pb-30__3aUzE {
  padding-bottom: 3rem; }

.New_tip_pl-5__1FgeT {
  padding-left: .5rem; }

.New_tip_pl-10__zntwU {
  padding-left: 1rem; }

.New_tip_pl-15__3eJiu {
  padding-left: 1.5rem; }

.New_tip_pl-20__31CWF {
  padding-left: 2rem; }

.New_tip_pl-25__2fVPh {
  padding-left: 2.5rem; }

.New_tip_pl-30__b4tM2 {
  padding-left: 3rem; }

.New_tip_pr-5__1Uiwr {
  padding-right: .5rem; }

.New_tip_pr-10__11PJk {
  padding-right: 1rem; }

.New_tip_pr-15__1iitc {
  padding-right: 1.5rem; }

.New_tip_pr-20__2VMCn {
  padding-right: 2rem; }

.New_tip_pr-25__Jf6mS {
  padding-right: 2.5rem; }

.New_tip_pr-30__2uuYx {
  padding-right: 3rem; }

html, body, #New_tip_root__3PkRN {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.New_tip_background_gradient__3Wgvv, .New_tip_newTip_wrapper__3NACf {
  height: 100%; }
  .New_tip_background_gradient__gray__2DaYc, .New_tip_newTip_wrapper__3NACf {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .New_tip_background_gradient__purpure__3cc3e {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.New_tip_card__active__3GosI, .New_tip_newTip_paymentSectionButton__ZfIu1 {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.New_tip_card__activeRotate__26fBP, .New_tip_newTip_paymentSectionButton__active__2VkGd {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.New_tip_card__disabled__2Plb0 {
  border: 2px solid #CDD8EF !important; }
  .New_tip_card__disabled__2Plb0 > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.New_tip_newTip_formTip__16S7G {
  position: absolute;
  left: -1000rem;
  margin-top: 1.0rem;
  max-height: .01rem;
  background: transparent;
  overflow: hidden;
  margin: 0;
  z-index: -1; }
  .New_tip_newTip_formTip__16S7G label {
    margin-bottom: 1.6rem !important;
    font-size: 1.8rem !important;
    font-weight: 700; }
  .New_tip_newTip_formTip__16S7G input {
    text-align: center;
    font-size: 2.4rem;
    padding: .6rem 1.4rem !important; }

.New_tip_newTip_titleTip__2u9ui {
  height: 40px;
  width: 100%;
  font-size: 2.6rem;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  background: white; }

.New_tip_newTip_subTitle__2vzuI {
  margin-top: .5rem;
  font-size: 1.4rem; }

.New_tip_newTip_cardBoxes__2Wa2Q {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .8rem; }

.New_tip_newTip_payment__1gM97 {
  height: 20rem; }

.New_tip_newTip_paymentSection__pxnD4 {
  margin-top: 2.5rem; }

.New_tip_newTip_paymentSectionButton__ZfIu1 {
  border: none !important; }
  .New_tip_newTip_paymentSectionButton__active__2VkGd {
    font-weight: 600 !important;
    border: none !important; }
  .New_tip_newTip_paymentSectionButton__disabled__2XTa0 {
    border: 2px solid #CDD8EF !important; }
    .New_tip_newTip_paymentSectionButton__disabled__2XTa0 > span {
      color: #CDD8EF !important;
      font-weight: 600; }

.New_tip_newTip_paymentSectionForm__BmxfF {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  .New_tip_newTip_paymentSectionForm__BmxfF button {
    grid-column: 1 / -1; }

.New_tip_newTip_paymentSectionBlikCode__2vAZr input::-webkit-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.New_tip_newTip_paymentSectionBlikCode__2vAZr input::-moz-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.New_tip_newTip_paymentSectionBlikCode__2vAZr input:-ms-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.New_tip_newTip_paymentSectionBlikCode__2vAZr input::-ms-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.New_tip_newTip_paymentSectionBlikCode__2vAZr input::placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.New_tip_newTip_paymentSectionTipCode__YlNiA {
  text-align: center; }

.New_tip_newTip_paymentSectionTipCodeWrapperImg__Uhrcp {
  height: 90px;
  margin-top: .9rem; }

.New_tip_newTip_newTip_paymentSectionTipCodeWrapperImg__3FFHj {
  height: 100%; }

.New_tip_newTip_spinner__small__1qG2D {
  display: grid;
  align-items: center;
  height: 15rem; }

.PriceInputFake_priceInputFake_titleTip__1qWGD {
  height: 40px;
  width: 100%;
  font-size: 2.6rem;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  background: white;
  box-sizing: border-box; }

/* Global variables */
.SpinnerSmall_lds-spinner-small__35EZC {
  justify-self: center;
  width: 64px;
  height: 64px; }

.SpinnerSmall_lds-spinner-small__35EZC div {
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
  -webkit-animation: SpinnerSmall_lds-spinner-small__35EZC 1.2s linear infinite;
          animation: SpinnerSmall_lds-spinner-small__35EZC 1.2s linear infinite; }

.SpinnerSmall_lds-spinner-small__35EZC div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 30px;
  width: 4px;
  height: 20px;
  border-radius: 25%;
  background: -webkit-gradient(linear, left top, left bottom, from(#E9649B), to(#2B0096));
  background: linear-gradient(#E9649B, #2B0096); }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

.SpinnerSmall_lds-spinner-small__35EZC div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

@-webkit-keyframes SpinnerSmall_lds-spinner-small__35EZC {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes SpinnerSmall_lds-spinner-small__35EZC {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* Global variables */
.ThankYou_container__1LHwH {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.ThankYou_title__3Hq7H {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .ThankYou_title_log__1DZLX {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .ThankYou_title__small__k7DiA {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .ThankYou_title__smallBold__3AZYV {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .ThankYou_title__marginTitle__XXCmF {
    margin: 2.2rem 0 1rem; }
  .ThankYou_title__err__2yNFK {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.ThankYou_inputValue__1WDG3 {
  font-weight: 700; }
  .ThankYou_inputValue__int__N0CNP {
    font-size: 3rem; }
  .ThankYou_inputValue__cent__3koXr {
    font-size: 2.2rem; }
  .ThankYou_inputValue__currency__1Y2fd {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.ThankYou_mt-5__38X2u {
  margin-top: .5rem; }

.ThankYou_mt-10__2O6tM {
  margin-top: 1rem; }

.ThankYou_mt-15__p4lk- {
  margin-top: 1.5rem; }

.ThankYou_mt-20__xT6K8 {
  margin-top: 2rem; }

.ThankYou_mt-25__h_SX- {
  margin-top: 2.5rem; }

.ThankYou_mt-30__1fo96 {
  margin-top: 3rem; }

.ThankYou_mb-5__2g7p2 {
  margin-bottom: .5rem; }

.ThankYou_mb-10__1ENXE {
  margin-bottom: 1rem; }

.ThankYou_mb-15__LviSu {
  margin-bottom: 1.5rem; }

.ThankYou_mb-20__3wkxn {
  margin-bottom: 2rem; }

.ThankYou_mb-25__1a4nK {
  margin-bottom: 2.5rem; }

.ThankYou_mb-30__3wGPi {
  margin-bottom: 3rem; }

.ThankYou_ml-5__1dNda {
  margin-left: .5rem; }

.ThankYou_ml-10__PSzMV {
  margin-left: 1rem; }

.ThankYou_ml-15__EgMrg {
  margin-left: 1.5rem; }

.ThankYou_ml-20__2IRS5 {
  margin-left: 2rem; }

.ThankYou_ml-25__eGs7d {
  margin-left: 2.5rem; }

.ThankYou_ml-30__2OYO3 {
  margin-left: 3rem; }

.ThankYou_mr-5__3urPp {
  margin-right: .5rem; }

.ThankYou_mr-10__10LHu {
  margin-right: 1rem; }

.ThankYou_mr-15__3Jkrq {
  margin-right: 1.5rem; }

.ThankYou_mr-20__2KViT {
  margin-right: 2rem; }

.ThankYou_mr-25__2ns4N {
  margin-right: 2.5rem; }

.ThankYou_mr-30__3UHJf {
  margin-right: 3rem; }

.ThankYou_pt-5__3UGEX {
  padding-top: .5rem; }

.ThankYou_pt-10__17Ale {
  padding-top: 1rem; }

.ThankYou_pt-15__Vwycz {
  padding-top: 1.5rem; }

.ThankYou_pt-20__-KW-0 {
  padding-top: 2rem; }

.ThankYou_pt-25__2VUoZ {
  padding-top: 2.5rem; }

.ThankYou_pt-30__169vH {
  padding-top: 3rem; }

.ThankYou_pb-5__1CEUB {
  padding-bottom: .5rem; }

.ThankYou_pb-10__hU3Bb {
  padding-bottom: 1rem; }

.ThankYou_pb-15__Xb-12 {
  padding-bottom: 1.5rem; }

.ThankYou_pb-20__1FlhO {
  padding-bottom: 2rem; }

.ThankYou_pb-25__MFg08 {
  padding-bottom: 2.5rem; }

.ThankYou_pb-30__2M_zw {
  padding-bottom: 3rem; }

.ThankYou_pl-5__3CU4H {
  padding-left: .5rem; }

.ThankYou_pl-10__3ZNm2 {
  padding-left: 1rem; }

.ThankYou_pl-15__2XkG1 {
  padding-left: 1.5rem; }

.ThankYou_pl-20__1hK8A {
  padding-left: 2rem; }

.ThankYou_pl-25__yBxNn {
  padding-left: 2.5rem; }

.ThankYou_pl-30__3jh11 {
  padding-left: 3rem; }

.ThankYou_pr-5__FxbU4 {
  padding-right: .5rem; }

.ThankYou_pr-10__300mP {
  padding-right: 1rem; }

.ThankYou_pr-15__7xz_x {
  padding-right: 1.5rem; }

.ThankYou_pr-20__2xasw {
  padding-right: 2rem; }

.ThankYou_pr-25__inm4q {
  padding-right: 2.5rem; }

.ThankYou_pr-30__2DCaD {
  padding-right: 3rem; }

html, body, #ThankYou_root__6xeFT {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ThankYou_background_gradient__2Gcc6 {
  height: 100%; }
  .ThankYou_background_gradient__gray__2VUeH {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .ThankYou_background_gradient__purpure__2bjJj {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.ThankYou_card__active__1SfZe {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.ThankYou_card__activeRotate__IYqO9 {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.ThankYou_card__disabled__3xEyq {
  border: 2px solid #CDD8EF !important; }
  .ThankYou_card__disabled__3xEyq > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.ThankYou_container__1LHwH {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  text-align: center; }
  .ThankYou_container__1LHwH h1 {
    font-size: 2.4rem;
    padding: 0 2rem; }

.ThankYou_images__1vyVW {
  display: grid;
  justify-content: center;
  max-width: 20rem;
  height: 17rem;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .ThankYou_images_bg__2MIYy {
    position: absolute;
    bottom: 0;
    height: 11.5rem;
    width: 100%;
    text-align: center;
    overflow: hidden; }
    .ThankYou_images_bg__2MIYy img {
      margin-top: -4.0rem;
      width: 100%; }
  .ThankYou_images_icon__3zS9r {
    height: 8.0rem;
    align-self: center;
    z-index: 2; }

.ThankYou_additionalText__3HDSR {
  font-size: 1.6rem;
  margin-bottom: 3rem; }

.ThankYou_link__3k3my {
  font-size: 1.8rem;
  text-decoration: underline;
  cursor: pointer; }

/* Global variables */
.Give_container__3Vt9Z {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.Give_title__1uFGz {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .Give_title_log__1P5lp {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .Give_title__small__36SE3 {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .Give_title__smallBold__1MgaU {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .Give_title__marginTitle__2AsGH {
    margin: 2.2rem 0 1rem; }
  .Give_title__err__1Zmg7 {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.Give_inputValue__3jmrS {
  font-weight: 700; }
  .Give_inputValue__int__jpYYt {
    font-size: 3rem; }
  .Give_inputValue__cent__o8jj2 {
    font-size: 2.2rem; }
  .Give_inputValue__currency__3ndyo {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.Give_mt-5__188bE {
  margin-top: .5rem; }

.Give_mt-10__JaANg {
  margin-top: 1rem; }

.Give_mt-15__2fyGz {
  margin-top: 1.5rem; }

.Give_mt-20__3dm1- {
  margin-top: 2rem; }

.Give_mt-25__1tgvj {
  margin-top: 2.5rem; }

.Give_mt-30__1kJM2 {
  margin-top: 3rem; }

.Give_mb-5__3cAit {
  margin-bottom: .5rem; }

.Give_mb-10__2pFIZ {
  margin-bottom: 1rem; }

.Give_mb-15__pV2cR {
  margin-bottom: 1.5rem; }

.Give_mb-20__321UV {
  margin-bottom: 2rem; }

.Give_mb-25__27330 {
  margin-bottom: 2.5rem; }

.Give_mb-30__puDET {
  margin-bottom: 3rem; }

.Give_ml-5__PqOda {
  margin-left: .5rem; }

.Give_ml-10__1VqFl {
  margin-left: 1rem; }

.Give_ml-15__G1vNg {
  margin-left: 1.5rem; }

.Give_ml-20__2_0qw {
  margin-left: 2rem; }

.Give_ml-25__389Zz {
  margin-left: 2.5rem; }

.Give_ml-30__savPM {
  margin-left: 3rem; }

.Give_mr-5__20Jtq {
  margin-right: .5rem; }

.Give_mr-10__8HyhM {
  margin-right: 1rem; }

.Give_mr-15__2IYhz {
  margin-right: 1.5rem; }

.Give_mr-20__1o_dW {
  margin-right: 2rem; }

.Give_mr-25__10n6R {
  margin-right: 2.5rem; }

.Give_mr-30__nJWZE {
  margin-right: 3rem; }

.Give_pt-5__sPgbV {
  padding-top: .5rem; }

.Give_pt-10__3k5EF {
  padding-top: 1rem; }

.Give_pt-15__1R-Zc {
  padding-top: 1.5rem; }

.Give_pt-20__3wM83 {
  padding-top: 2rem; }

.Give_pt-25__3AjK8 {
  padding-top: 2.5rem; }

.Give_pt-30__1yduZ {
  padding-top: 3rem; }

.Give_pb-5__3vvkv {
  padding-bottom: .5rem; }

.Give_pb-10__2ithc {
  padding-bottom: 1rem; }

.Give_pb-15__uqddh {
  padding-bottom: 1.5rem; }

.Give_pb-20__3UkUl {
  padding-bottom: 2rem; }

.Give_pb-25__2UYwy {
  padding-bottom: 2.5rem; }

.Give_pb-30__MlVY4 {
  padding-bottom: 3rem; }

.Give_pl-5__rQZ9s {
  padding-left: .5rem; }

.Give_pl-10__397T0 {
  padding-left: 1rem; }

.Give_pl-15__35oI9 {
  padding-left: 1.5rem; }

.Give_pl-20__R9PDs {
  padding-left: 2rem; }

.Give_pl-25__3N0Fo {
  padding-left: 2.5rem; }

.Give_pl-30__oObEo {
  padding-left: 3rem; }

.Give_pr-5__3Unr5 {
  padding-right: .5rem; }

.Give_pr-10__15O4Y {
  padding-right: 1rem; }

.Give_pr-15__1E8ma {
  padding-right: 1.5rem; }

.Give_pr-20__1YR9q {
  padding-right: 2rem; }

.Give_pr-25__mnNOx {
  padding-right: 2.5rem; }

.Give_pr-30__2wyIP {
  padding-right: 3rem; }

html, body, #Give_root__hfdiM {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Give_background_gradient__1Hc-Q, .Give_give_wrapper__1rreG {
  height: 100%; }
  .Give_background_gradient__gray__2fGsm, .Give_give_wrapper__1rreG {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .Give_background_gradient__purpure__3e6Ur {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.Give_card__active__yeYtd {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Give_card__activeRotate__53kdN, .Give_give_paymentSectionButton__active__udvLx {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Give_card__disabled__3EcHV {
  border: 2px solid #CDD8EF !important; }
  .Give_card__disabled__3EcHV > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.Give_give_tip__nrxy9 {
  font-weight: 700; }

.Give_give_tipPrice__2v0dZ {
  color: #E9649B; }
  .Give_give_tipPrice__size22__3KTZ1 {
    font-size: 2.2rem; }
  .Give_give_tipPrice__size18__3v9PI {
    font-size: 1.8rem; }

.Give_give_titleTip__RaF8W {
  height: 40px;
  width: 100%;
  font-size: 2.6rem;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  background: white; }

.Give_give_subTitle__3DOt6 {
  margin-top: .5rem;
  font-size: 1.4rem; }

.Give_give_payment__1UoII {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4.4rem;
  text-align: center;
  height: 6rem; }

.Give_give_paymentItem__Gc50i {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  background: white;
  border: 2px dotted white;
  border-radius: .6rem;
  height: 6rem;
  box-sizing: border-box; }
  .Give_give_paymentItem__selected__3AGBG {
    border: 2px solid #2B0096; }

.Give_give_gpayButton__3GRnr button {
  width: 100% !important;
  height: 100%;
  box-shadow: none !important; }

.Give_give_blikCode__39ZKe input::-webkit-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.Give_give_blikCode__39ZKe input::-moz-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.Give_give_blikCode__39ZKe input:-ms-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.Give_give_blikCode__39ZKe input::-ms-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.Give_give_blikCode__39ZKe input::placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.Give_give_paymentSectionButton__active__udvLx {
  font-weight: 600 !important;
  border: none !important; }

.Give_give_paymentSectionButton__disabled__11Yky {
  border: 2px solid #CDD8EF !important; }
  .Give_give_paymentSectionButton__disabled__11Yky > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.Give_give_thankYouPage__3Kq4W > div {
  color: #404040;
  margin-top: -.5rem; }

/* Global variables */
.PriceStyling_container__1BKwm {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.PriceStyling_title__1CXlx {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .PriceStyling_title_log__lAoLO {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .PriceStyling_title__small__2CnUR {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .PriceStyling_title__smallBold__9A9xa {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .PriceStyling_title__marginTitle__3dBt7 {
    margin: 2.2rem 0 1rem; }
  .PriceStyling_title__err__2bVP9 {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.PriceStyling_inputValue__1r0Js {
  font-weight: 700; }
  .PriceStyling_inputValue__int__hE_MO {
    font-size: 3rem; }
  .PriceStyling_inputValue__cent__df1qn {
    font-size: 2.2rem; }
  .PriceStyling_inputValue__currency__2h4-7 {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.PriceStyling_mt-5__3cSqu {
  margin-top: .5rem; }

.PriceStyling_mt-10__hgzQT {
  margin-top: 1rem; }

.PriceStyling_mt-15__4-X2L {
  margin-top: 1.5rem; }

.PriceStyling_mt-20__3J5EF {
  margin-top: 2rem; }

.PriceStyling_mt-25__mUNsH {
  margin-top: 2.5rem; }

.PriceStyling_mt-30__3G5P8 {
  margin-top: 3rem; }

.PriceStyling_mb-5__28aBS {
  margin-bottom: .5rem; }

.PriceStyling_mb-10__1ClRh {
  margin-bottom: 1rem; }

.PriceStyling_mb-15__1R4rF {
  margin-bottom: 1.5rem; }

.PriceStyling_mb-20__2TchJ {
  margin-bottom: 2rem; }

.PriceStyling_mb-25__2DQFW {
  margin-bottom: 2.5rem; }

.PriceStyling_mb-30__1K6vt {
  margin-bottom: 3rem; }

.PriceStyling_ml-5__22eE1 {
  margin-left: .5rem; }

.PriceStyling_ml-10__15B09 {
  margin-left: 1rem; }

.PriceStyling_ml-15__17hWi {
  margin-left: 1.5rem; }

.PriceStyling_ml-20__3nfUl {
  margin-left: 2rem; }

.PriceStyling_ml-25__10yT2 {
  margin-left: 2.5rem; }

.PriceStyling_ml-30__1sQYG {
  margin-left: 3rem; }

.PriceStyling_mr-5__34Ceo {
  margin-right: .5rem; }

.PriceStyling_mr-10__2TVAb {
  margin-right: 1rem; }

.PriceStyling_mr-15__WA4el {
  margin-right: 1.5rem; }

.PriceStyling_mr-20__3W_2A {
  margin-right: 2rem; }

.PriceStyling_mr-25__2I2Vq {
  margin-right: 2.5rem; }

.PriceStyling_mr-30__fxCeR {
  margin-right: 3rem; }

.PriceStyling_pt-5__QITGS {
  padding-top: .5rem; }

.PriceStyling_pt-10__1e48l {
  padding-top: 1rem; }

.PriceStyling_pt-15__PgCrR {
  padding-top: 1.5rem; }

.PriceStyling_pt-20__vR6jp {
  padding-top: 2rem; }

.PriceStyling_pt-25__1AI7v {
  padding-top: 2.5rem; }

.PriceStyling_pt-30__399vZ {
  padding-top: 3rem; }

.PriceStyling_pb-5__3MOEv {
  padding-bottom: .5rem; }

.PriceStyling_pb-10__1v81q {
  padding-bottom: 1rem; }

.PriceStyling_pb-15__Yxxb9 {
  padding-bottom: 1.5rem; }

.PriceStyling_pb-20__ace1q {
  padding-bottom: 2rem; }

.PriceStyling_pb-25__ZAo0u {
  padding-bottom: 2.5rem; }

.PriceStyling_pb-30__3hACj {
  padding-bottom: 3rem; }

.PriceStyling_pl-5__1oWoO {
  padding-left: .5rem; }

.PriceStyling_pl-10__3l6D6 {
  padding-left: 1rem; }

.PriceStyling_pl-15__1wtE8 {
  padding-left: 1.5rem; }

.PriceStyling_pl-20__1tl3G {
  padding-left: 2rem; }

.PriceStyling_pl-25__2vlCK {
  padding-left: 2.5rem; }

.PriceStyling_pl-30__3n1iA {
  padding-left: 3rem; }

.PriceStyling_pr-5__1YEvV {
  padding-right: .5rem; }

.PriceStyling_pr-10__1M6v_ {
  padding-right: 1rem; }

.PriceStyling_pr-15__1vJ5I {
  padding-right: 1.5rem; }

.PriceStyling_pr-20__D7bso {
  padding-right: 2rem; }

.PriceStyling_pr-25__27lJ- {
  padding-right: 2.5rem; }

.PriceStyling_pr-30__2Pp05 {
  padding-right: 3rem; }

html, body, #PriceStyling_root__1QFrz {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.PriceStyling_background_gradient__2vfpf {
  height: 100%; }
  .PriceStyling_background_gradient__gray__2g1D- {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .PriceStyling_background_gradient__purpure__hvMO3 {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.PriceStyling_card__active__2AZaP {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.PriceStyling_card__activeRotate__3LzZ0 {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.PriceStyling_card__disabled__32yTP {
  border: 2px solid #CDD8EF !important; }
  .PriceStyling_card__disabled__32yTP > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.PriceStyling_tipPrice__2molp {
  color: #E9649B; }
  .PriceStyling_tipPrice__size22__1exUj {
    font-size: 2.2rem; }
  .PriceStyling_tipPrice__size18__HwhjP {
    font-size: 1.8rem; }

/* Global variables */
.GPay_GPay_paymentItem__306Hi {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  background: white;
  border: 2px dotted white;
  border-radius: .6rem;
  height: 6rem;
  box-sizing: border-box; }
  .GPay_GPay_paymentItem__selected__2D3Tj {
    border: 2px solid #2B0096; }

/* Global variables */
.ApplePay_ApplePay_paymentItem__1XIgK {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  background: white;
  border: 2px dotted white;
  border-radius: .6rem;
  height: 6rem;
  box-sizing: border-box; }
  .ApplePay_ApplePay_paymentItem__selected__1yO_B {
    border: 2px solid #2B0096; }

/* Global variables */
.About_about__3OSTl {
  justify-items: left;
  align-items: end;
  margin: 0 auto;
  font-size: 1.4rem; }
  .About_about_smallTitle__3yqYc {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 1rem 0; }
  .About_about_versionNumbers__2djZf {
    display: grid;
    grid-template-columns: auto auto; }
    .About_about_versionNumbers__margin__NMlt- {
      margin: 0 5rem 1rem 0; }
  .About_about_whatsNew__OS3Er {
    margin-bottom: 1rem; }
    .About_about_whatsNew__OS3Er div:last-child {
      margin: 2rem 0; }
    .About_about_whatsNew__OS3Er a {
      color: black;
      font-weight: 600; }
    .About_about_whatsNew__OS3Er p {
      margin: 0.3rem 0; }
  .About_about_termsLink__3ifvh {
    font-size: 1.6rem;
    color: #404040; }

/* Global variables */
.TipCode_container__3JFoa {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.TipCode_title__3a41H {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .TipCode_title_log__2wWQb {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .TipCode_title__small__3Iogm {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .TipCode_title__smallBold__3ebmz {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .TipCode_title__marginTitle__3a510 {
    margin: 2.2rem 0 1rem; }
  .TipCode_title__err__1Nu1d {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.TipCode_inputValue__2Bqlw {
  font-weight: 700; }
  .TipCode_inputValue__int__1hBk5 {
    font-size: 3rem; }
  .TipCode_inputValue__cent__2pB6b {
    font-size: 2.2rem; }
  .TipCode_inputValue__currency__19uOx {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.TipCode_mt-5__1uyqL {
  margin-top: .5rem; }

.TipCode_mt-10__3k6da {
  margin-top: 1rem; }

.TipCode_mt-15__2FMJn {
  margin-top: 1.5rem; }

.TipCode_mt-20__3hUc4 {
  margin-top: 2rem; }

.TipCode_mt-25__2b0Sx {
  margin-top: 2.5rem; }

.TipCode_mt-30__21nNe {
  margin-top: 3rem; }

.TipCode_mb-5__3NYvl {
  margin-bottom: .5rem; }

.TipCode_mb-10__3wZRo {
  margin-bottom: 1rem; }

.TipCode_mb-15__2j_5s {
  margin-bottom: 1.5rem; }

.TipCode_mb-20__3atMx {
  margin-bottom: 2rem; }

.TipCode_mb-25__1rdcW {
  margin-bottom: 2.5rem; }

.TipCode_mb-30__3hj2i {
  margin-bottom: 3rem; }

.TipCode_ml-5__3oDE0 {
  margin-left: .5rem; }

.TipCode_ml-10__1k4X1 {
  margin-left: 1rem; }

.TipCode_ml-15__2G1xt {
  margin-left: 1.5rem; }

.TipCode_ml-20__3YZ1W {
  margin-left: 2rem; }

.TipCode_ml-25__3UEFJ {
  margin-left: 2.5rem; }

.TipCode_ml-30__2WOyE {
  margin-left: 3rem; }

.TipCode_mr-5__2AEHZ {
  margin-right: .5rem; }

.TipCode_mr-10__XcKUd {
  margin-right: 1rem; }

.TipCode_mr-15__1ZS_I {
  margin-right: 1.5rem; }

.TipCode_mr-20__3rndh {
  margin-right: 2rem; }

.TipCode_mr-25__3aaBs {
  margin-right: 2.5rem; }

.TipCode_mr-30__3lNAg {
  margin-right: 3rem; }

.TipCode_pt-5__12ffN {
  padding-top: .5rem; }

.TipCode_pt-10__657_i {
  padding-top: 1rem; }

.TipCode_pt-15__ZFvNf {
  padding-top: 1.5rem; }

.TipCode_pt-20__3y19H {
  padding-top: 2rem; }

.TipCode_pt-25__3qEt7 {
  padding-top: 2.5rem; }

.TipCode_pt-30__3bxKb {
  padding-top: 3rem; }

.TipCode_pb-5__39DED {
  padding-bottom: .5rem; }

.TipCode_pb-10__3iPCm {
  padding-bottom: 1rem; }

.TipCode_pb-15__O73TT {
  padding-bottom: 1.5rem; }

.TipCode_pb-20__1VAAZ {
  padding-bottom: 2rem; }

.TipCode_pb-25__3S5F5 {
  padding-bottom: 2.5rem; }

.TipCode_pb-30__1ki15 {
  padding-bottom: 3rem; }

.TipCode_pl-5__1ur1u {
  padding-left: .5rem; }

.TipCode_pl-10__2Die2 {
  padding-left: 1rem; }

.TipCode_pl-15__1i9Uz {
  padding-left: 1.5rem; }

.TipCode_pl-20__1kZtq {
  padding-left: 2rem; }

.TipCode_pl-25__31Fk1 {
  padding-left: 2.5rem; }

.TipCode_pl-30__1e-JN {
  padding-left: 3rem; }

.TipCode_pr-5__1o-RO {
  padding-right: .5rem; }

.TipCode_pr-10__2CLyd {
  padding-right: 1rem; }

.TipCode_pr-15__3norR {
  padding-right: 1.5rem; }

.TipCode_pr-20__1Z3Pl {
  padding-right: 2rem; }

.TipCode_pr-25__2aNsy {
  padding-right: 2.5rem; }

.TipCode_pr-30__3x1w4 {
  padding-right: 3rem; }

html, body, #TipCode_root__3oHBu {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.TipCode_background_gradient__2p7C1, .TipCode_tipCode_wrapper__2QSPo {
  height: 100%; }
  .TipCode_background_gradient__gray__oxmZR, .TipCode_tipCode_wrapper__2QSPo {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .TipCode_background_gradient__purpure__lOTlO {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.TipCode_card__active__Vr2I5 {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipCode_card__activeRotate__3evwP {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipCode_card__disabled__2jOAe {
  border: 2px solid #CDD8EF !important; }
  .TipCode_card__disabled__2jOAe > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.TipCode_tipCode_container__8hNwJ {
  display: grid; }

.TipCode_tipCode_title__9ekt5 {
  font-size: 2.4rem;
  margin-top: 0.9rem; }

.TipCode_tipCode_subTitle__3DiDJ {
  font-size: 1.6rem;
  margin-top: 2.3rem; }

.TipCode_tipCode_qrCodeWrapper__1eMJQ {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 140px; }

.TipCode_tipCode_printButtonsWrapper__1IzQm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 2rem 0;
  justify-items: center;
  grid-column-gap: 1.8rem;
  -webkit-column-gap: 1.8rem;
     -moz-column-gap: 1.8rem;
          column-gap: 1.8rem; }
  .TipCode_tipCode_printButtonsWrapper__link__33n3D {
    text-decoration: none; }
  .TipCode_tipCode_printButtonsWrapper__padding__3APVw {
    padding-top: 0.05rem; }

.TipCode_tipCode_printButton__FwHyI {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 4.8rem;
  max-height: 5.8rem;
  padding: 1.1rem 0 0.8rem 0;
  border-radius: .7rem;
  background: white;
  box-shadow: .1rem .3rem .6rem 0px #ccc;
  font-size: 1.6rem; }
  .TipCode_tipCode_printButton__bold__3-FSn {
    font-weight: 700;
    color: #404040;
    margin-bottom: 0.5rem; }
  .TipCode_tipCode_printButton__info__3xDJ7 {
    color: #AFAFAF;
    font-size: 1.1rem;
    margin: 0 1rem; }

/* Global variables */
.TipCodeGive_container__1Ttl7 {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.TipCodeGive_title__NzR6j {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .TipCodeGive_title_log__316b1 {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .TipCodeGive_title__small__BI6J6 {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .TipCodeGive_title__smallBold__1aA5h {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .TipCodeGive_title__marginTitle__395FE {
    margin: 2.2rem 0 1rem; }
  .TipCodeGive_title__err__11VeF {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.TipCodeGive_inputValue__14nw2 {
  font-weight: 700; }
  .TipCodeGive_inputValue__int__1nXLa {
    font-size: 3rem; }
  .TipCodeGive_inputValue__cent__1FVYb {
    font-size: 2.2rem; }
  .TipCodeGive_inputValue__currency__27dcm {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.TipCodeGive_mt-5__2lk6_ {
  margin-top: .5rem; }

.TipCodeGive_mt-10__bbsKM {
  margin-top: 1rem; }

.TipCodeGive_mt-15__ikMdS {
  margin-top: 1.5rem; }

.TipCodeGive_mt-20__d6zbM {
  margin-top: 2rem; }

.TipCodeGive_mt-25__1sOws {
  margin-top: 2.5rem; }

.TipCodeGive_mt-30__1jLcu {
  margin-top: 3rem; }

.TipCodeGive_mb-5__X6L_q {
  margin-bottom: .5rem; }

.TipCodeGive_mb-10__3Brnk {
  margin-bottom: 1rem; }

.TipCodeGive_mb-15__360PM {
  margin-bottom: 1.5rem; }

.TipCodeGive_mb-20__g1FiK {
  margin-bottom: 2rem; }

.TipCodeGive_mb-25__1Y1Pc {
  margin-bottom: 2.5rem; }

.TipCodeGive_mb-30__2qpvi {
  margin-bottom: 3rem; }

.TipCodeGive_ml-5__utLAY {
  margin-left: .5rem; }

.TipCodeGive_ml-10__2LY0L {
  margin-left: 1rem; }

.TipCodeGive_ml-15__n1hsS {
  margin-left: 1.5rem; }

.TipCodeGive_ml-20__36v_M {
  margin-left: 2rem; }

.TipCodeGive_ml-25__2Xlj4 {
  margin-left: 2.5rem; }

.TipCodeGive_ml-30__1-rcJ {
  margin-left: 3rem; }

.TipCodeGive_mr-5__3V33Q {
  margin-right: .5rem; }

.TipCodeGive_mr-10__30wq5 {
  margin-right: 1rem; }

.TipCodeGive_mr-15__49TpM {
  margin-right: 1.5rem; }

.TipCodeGive_mr-20__2WU7U {
  margin-right: 2rem; }

.TipCodeGive_mr-25__3NvoB {
  margin-right: 2.5rem; }

.TipCodeGive_mr-30__3u0WD {
  margin-right: 3rem; }

.TipCodeGive_pt-5__1wgGN {
  padding-top: .5rem; }

.TipCodeGive_pt-10__1u5rJ {
  padding-top: 1rem; }

.TipCodeGive_pt-15__mkmmU {
  padding-top: 1.5rem; }

.TipCodeGive_pt-20__1ebk9 {
  padding-top: 2rem; }

.TipCodeGive_pt-25__2nKOI {
  padding-top: 2.5rem; }

.TipCodeGive_pt-30__2tviL {
  padding-top: 3rem; }

.TipCodeGive_pb-5__VM4NK {
  padding-bottom: .5rem; }

.TipCodeGive_pb-10__3_9Fc {
  padding-bottom: 1rem; }

.TipCodeGive_pb-15__1muD8 {
  padding-bottom: 1.5rem; }

.TipCodeGive_pb-20__3sKZM {
  padding-bottom: 2rem; }

.TipCodeGive_pb-25__2spad {
  padding-bottom: 2.5rem; }

.TipCodeGive_pb-30__2WA6w {
  padding-bottom: 3rem; }

.TipCodeGive_pl-5__3E79- {
  padding-left: .5rem; }

.TipCodeGive_pl-10__DK7kE {
  padding-left: 1rem; }

.TipCodeGive_pl-15__uEQOl {
  padding-left: 1.5rem; }

.TipCodeGive_pl-20__nV1tw {
  padding-left: 2rem; }

.TipCodeGive_pl-25__nf8st {
  padding-left: 2.5rem; }

.TipCodeGive_pl-30__3fGRc {
  padding-left: 3rem; }

.TipCodeGive_pr-5__3gShk {
  padding-right: .5rem; }

.TipCodeGive_pr-10__bvys- {
  padding-right: 1rem; }

.TipCodeGive_pr-15__1hO1c {
  padding-right: 1.5rem; }

.TipCodeGive_pr-20__DkA2e {
  padding-right: 2rem; }

.TipCodeGive_pr-25__G_Q4O {
  padding-right: 2.5rem; }

.TipCodeGive_pr-30__lkBYH {
  padding-right: 3rem; }

html, body, #TipCodeGive_root__9wZxr {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.TipCodeGive_background_gradient__ayK9n, .TipCodeGive_tipCodeGive_wrapper__2_f5J {
  height: 100%; }
  .TipCodeGive_background_gradient__gray__8owpi, .TipCodeGive_tipCodeGive_wrapper__2_f5J {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .TipCodeGive_background_gradient__purpure__3Sk83 {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.TipCodeGive_card__active__DqSRH {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipCodeGive_card__activeRotate__1Y2iB, .TipCodeGive_tipCodeGive_paymentSectionButton__active__1SQJW {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipCodeGive_card__disabled__3cNUg {
  border: 2px solid #CDD8EF !important; }
  .TipCodeGive_card__disabled__3cNUg > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.TipCodeGive_tipCodeGive_container__3ifqG {
  display: grid; }

.TipCodeGive_tipCodeGive_thankYou__1bbgs {
  text-align: left; }
  .TipCodeGive_tipCodeGive_thankYou__1bbgs > div {
    padding: 0; }
  .TipCodeGive_tipCodeGive_thankYou__title__3lDDX {
    margin-top: 0;
    text-align: left;
    font-weight: 400; }
  .TipCodeGive_tipCodeGive_thankYou__text__J5neP {
    margin-top: 2.3rem;
    text-align: left;
    font-size: 1.6rem; }
  .TipCodeGive_tipCodeGive_thankYou__1bbgs button {
    border: none; }

.TipCodeGive_tipCodeGive_title__195yh {
  font-size: 2.4rem;
  margin-top: 0.9rem; }

.TipCodeGive_tipCodeGive_subTitle__1VHTX {
  font-size: 1.6rem;
  margin-top: .5rem; }

.TipCodeGive_tipCodeGive_formTip__2lpj7 {
  position: absolute;
  left: -1000rem;
  margin-top: 1.0rem;
  max-height: .01rem;
  background: transparent;
  overflow: hidden;
  margin: 0;
  z-index: -1; }
  .TipCodeGive_tipCodeGive_formTip__2lpj7 label {
    margin-bottom: 1.6rem !important;
    font-size: 1.8rem !important;
    font-weight: 700; }
  .TipCodeGive_tipCodeGive_formTip__2lpj7 input {
    text-align: center;
    font-size: 2.4rem;
    padding: .6rem 1.4rem !important; }

.TipCodeGive_tipCodeGive_titleTip__PKFqp {
  height: 40px;
  width: 100%;
  font-size: 2.6rem;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  background: white; }

.TipCodeGive_tipCodeGive_cardBoxes__BPMq5 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4.4rem;
  margin-bottom: 4rem; }

.TipCodeGive_tipCodeGive_gpayButton__5bMo2 button {
  width: 100% !important;
  height: 100%;
  box-shadow: none !important; }

.TipCodeGive_tipCodeGive_blikCode__3278t input::-webkit-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.TipCodeGive_tipCodeGive_blikCode__3278t input::-moz-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.TipCodeGive_tipCodeGive_blikCode__3278t input:-ms-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.TipCodeGive_tipCodeGive_blikCode__3278t input::-ms-input-placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.TipCodeGive_tipCodeGive_blikCode__3278t input::placeholder {
  position: relative;
  top: -2px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2; }

.TipCodeGive_tipCodeGive_paymentItem__1701L {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  background: white;
  border: 2px dotted white;
  border-radius: .6rem;
  height: 6rem;
  box-sizing: border-box; }
  .TipCodeGive_tipCodeGive_paymentItem__selected__1SXMO {
    border: 2px solid #2B0096; }

.TipCodeGive_tipCodeGive_paymentSectionButton__active__1SQJW {
  font-weight: 600 !important;
  border: none !important; }

.TipCodeGive_tipCodeGive_paymentSectionButton__disabled__3JyL- {
  border: 2px solid #CDD8EF !important; }
  .TipCodeGive_tipCodeGive_paymentSectionButton__disabled__3JyL- > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.TipCodeGive_tipCodeGive_paymentTYP__DFNwc > div {
  color: #404040;
  margin-top: -.5rem; }

/* Global variables */
.Tips_container__2LDXd {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.Tips_title__13CK2 {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .Tips_title_log__Rlle3 {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .Tips_title__small__2s0LK {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .Tips_title__smallBold__1Jh28 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .Tips_title__marginTitle__3F4SP {
    margin: 2.2rem 0 1rem; }
  .Tips_title__err__21Qq2 {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.Tips_inputValue__PTQY1 {
  font-weight: 700; }
  .Tips_inputValue__int__3LXLF {
    font-size: 3rem; }
  .Tips_inputValue__cent__16Vvl {
    font-size: 2.2rem; }
  .Tips_inputValue__currency__2dkxg {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.Tips_mt-5__3x2ld {
  margin-top: .5rem; }

.Tips_mt-10__39TUZ {
  margin-top: 1rem; }

.Tips_mt-15__2sY8E {
  margin-top: 1.5rem; }

.Tips_mt-20__dnlGr {
  margin-top: 2rem; }

.Tips_mt-25__1SnBg {
  margin-top: 2.5rem; }

.Tips_mt-30__227Uz {
  margin-top: 3rem; }

.Tips_mb-5__2qSpC {
  margin-bottom: .5rem; }

.Tips_mb-10__1PukK {
  margin-bottom: 1rem; }

.Tips_mb-15__RGgBw {
  margin-bottom: 1.5rem; }

.Tips_mb-20__M9m1R {
  margin-bottom: 2rem; }

.Tips_mb-25__2zA6c {
  margin-bottom: 2.5rem; }

.Tips_mb-30__Zyt26 {
  margin-bottom: 3rem; }

.Tips_ml-5__32o5O {
  margin-left: .5rem; }

.Tips_ml-10__1Y0Nf {
  margin-left: 1rem; }

.Tips_ml-15__V0ykO {
  margin-left: 1.5rem; }

.Tips_ml-20__eF2-K {
  margin-left: 2rem; }

.Tips_ml-25__3WeLn {
  margin-left: 2.5rem; }

.Tips_ml-30__3pwUN {
  margin-left: 3rem; }

.Tips_mr-5__3MUda {
  margin-right: .5rem; }

.Tips_mr-10__1F82U {
  margin-right: 1rem; }

.Tips_mr-15__2n_2a {
  margin-right: 1.5rem; }

.Tips_mr-20__2Favr {
  margin-right: 2rem; }

.Tips_mr-25__1uDcJ {
  margin-right: 2.5rem; }

.Tips_mr-30__3OQCz {
  margin-right: 3rem; }

.Tips_pt-5__3WZI6 {
  padding-top: .5rem; }

.Tips_pt-10__3lHvT {
  padding-top: 1rem; }

.Tips_pt-15__J9qrz {
  padding-top: 1.5rem; }

.Tips_pt-20__31yNB {
  padding-top: 2rem; }

.Tips_pt-25__1cV3B {
  padding-top: 2.5rem; }

.Tips_pt-30__18z-0 {
  padding-top: 3rem; }

.Tips_pb-5__3CV6l {
  padding-bottom: .5rem; }

.Tips_pb-10__hIg14 {
  padding-bottom: 1rem; }

.Tips_pb-15__1DwMd {
  padding-bottom: 1.5rem; }

.Tips_pb-20__2-b8g {
  padding-bottom: 2rem; }

.Tips_pb-25__V6p0r {
  padding-bottom: 2.5rem; }

.Tips_pb-30__3dAa7 {
  padding-bottom: 3rem; }

.Tips_pl-5__xYMsR {
  padding-left: .5rem; }

.Tips_pl-10__2W798 {
  padding-left: 1rem; }

.Tips_pl-15__1PIcO {
  padding-left: 1.5rem; }

.Tips_pl-20__ikL8n {
  padding-left: 2rem; }

.Tips_pl-25__1K3qs {
  padding-left: 2.5rem; }

.Tips_pl-30__2rvyj {
  padding-left: 3rem; }

.Tips_pr-5__3nsns {
  padding-right: .5rem; }

.Tips_pr-10__1gPAg {
  padding-right: 1rem; }

.Tips_pr-15__2HA1j {
  padding-right: 1.5rem; }

.Tips_pr-20__2fOkZ {
  padding-right: 2rem; }

.Tips_pr-25__8yZLZ {
  padding-right: 2.5rem; }

.Tips_pr-30__3lCNx {
  padding-right: 3rem; }

html, body, #Tips_root__1OVGQ {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Tips_background_gradient__1Hj0I, .Tips_tips_wrapper__3mqbQ {
  height: 100%; }
  .Tips_background_gradient__gray__1p4ql, .Tips_tips_wrapper__3mqbQ {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .Tips_background_gradient__purpure__1Wjw8 {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.Tips_card__active__8OxXu, .Tips_tips_periodItem__active__opcvZ {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Tips_card__activeRotate__2ICVX {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Tips_card__disabled__33_hT {
  border: 2px solid #CDD8EF !important; }
  .Tips_card__disabled__33_hT > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.Tips_tips_title__Jd_Ar {
  font-size: 2.4rem;
  margin-top: 0.9rem; }

.Tips_tips_subTitle__3YFl3 {
  margin-top: .5rem;
  font-size: 1.4rem; }

.Tips_tips_period__3gfMV {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .7rem; }

.Tips_tips_periodWrapper__xXsUD {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .8rem;
  margin: 2rem 0; }

.Tips_tips_periodItem__xg92G {
  display: grid;
  width: 100%;
  padding: 2.1rem 0;
  border-radius: .7rem;
  background: white;
  box-shadow: .1rem .3rem .6rem 0px #ccc; }
  .Tips_tips_periodItem__active__opcvZ {
    font-weight: 600 !important;
    border: none !important; }

.Tips_tips_periodItemTime__28F2i {
  margin-bottom: .7rem;
  font-size: 1.2rem;
  color: #AFAFAF; }

.Tips_tips_periodItemAmount__4_Rj9 {
  font-weight: 700;
  font-size: 1.4rem; }

.Tips_tips_dateList__1ZUXo {
  font-size: 1.4rem; }

.Tips_tips_dateTitle__1SfH8 {
  font-weight: 700; }

.Tips_tips_dateTable__3m6JM {
  width: 100%; }

.Tips_tips_dateTableValue__1cQMG {
  font-weight: 700;
  text-align: right; }

.Tips_tips_hourCellSingle__1OkJ6 {
  width: 9rem; }

.Tips_tips_hourCellTipBox__3RjKQ {
  width: 5rem; }

.Tips_tips_nameCell__3Vcya {
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.Tips_tips_dateTableRow__4ZaAW {
  line-height: 2rem; }

/* Global variables */
.TipBox_container__27xJv {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.TipBox_title__1VBTy {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .TipBox_title_log__3iq6I {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .TipBox_title__small__3Pqf6 {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .TipBox_title__smallBold__nFhAd {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .TipBox_title__marginTitle__2FrUq {
    margin: 2.2rem 0 1rem; }
  .TipBox_title__err__skw5x {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.TipBox_inputValue__3lF4R {
  font-weight: 700; }
  .TipBox_inputValue__int__3fawW {
    font-size: 3rem; }
  .TipBox_inputValue__cent__1Tm7M {
    font-size: 2.2rem; }
  .TipBox_inputValue__currency__DZxu7 {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.TipBox_mt-5__25xSO {
  margin-top: .5rem; }

.TipBox_mt-10__Om84q {
  margin-top: 1rem; }

.TipBox_mt-15__1lqbE {
  margin-top: 1.5rem; }

.TipBox_mt-20__2Ey3r {
  margin-top: 2rem; }

.TipBox_mt-25__3GAIl {
  margin-top: 2.5rem; }

.TipBox_mt-30__12DBk {
  margin-top: 3rem; }

.TipBox_mb-5__1T38I {
  margin-bottom: .5rem; }

.TipBox_mb-10__tNPJs {
  margin-bottom: 1rem; }

.TipBox_mb-15__3r7Ya {
  margin-bottom: 1.5rem; }

.TipBox_mb-20__30xku {
  margin-bottom: 2rem; }

.TipBox_mb-25__10jTa {
  margin-bottom: 2.5rem; }

.TipBox_mb-30__1k5rB {
  margin-bottom: 3rem; }

.TipBox_ml-5__3zIiM {
  margin-left: .5rem; }

.TipBox_ml-10__2QR6L {
  margin-left: 1rem; }

.TipBox_ml-15__LEabQ {
  margin-left: 1.5rem; }

.TipBox_ml-20__3J3AZ {
  margin-left: 2rem; }

.TipBox_ml-25__2_m2D {
  margin-left: 2.5rem; }

.TipBox_ml-30__1pZ1R {
  margin-left: 3rem; }

.TipBox_mr-5__2SxDQ {
  margin-right: .5rem; }

.TipBox_mr-10__3Mwh- {
  margin-right: 1rem; }

.TipBox_mr-15__39BdU {
  margin-right: 1.5rem; }

.TipBox_mr-20__29aOG {
  margin-right: 2rem; }

.TipBox_mr-25__1MO94 {
  margin-right: 2.5rem; }

.TipBox_mr-30__Btn86 {
  margin-right: 3rem; }

.TipBox_pt-5__3_HWx {
  padding-top: .5rem; }

.TipBox_pt-10__173fm {
  padding-top: 1rem; }

.TipBox_pt-15__1Z2PK {
  padding-top: 1.5rem; }

.TipBox_pt-20__JTwTX {
  padding-top: 2rem; }

.TipBox_pt-25__LNbMk {
  padding-top: 2.5rem; }

.TipBox_pt-30__UAtqv {
  padding-top: 3rem; }

.TipBox_pb-5__3s5ES {
  padding-bottom: .5rem; }

.TipBox_pb-10__yz2e7 {
  padding-bottom: 1rem; }

.TipBox_pb-15__gVplM {
  padding-bottom: 1.5rem; }

.TipBox_pb-20__3qc1r {
  padding-bottom: 2rem; }

.TipBox_pb-25__1NMUR {
  padding-bottom: 2.5rem; }

.TipBox_pb-30__W35lH {
  padding-bottom: 3rem; }

.TipBox_pl-5__PRe1S {
  padding-left: .5rem; }

.TipBox_pl-10__1CBKn {
  padding-left: 1rem; }

.TipBox_pl-15__KZHeb {
  padding-left: 1.5rem; }

.TipBox_pl-20__1oQZ0 {
  padding-left: 2rem; }

.TipBox_pl-25__2pIWv {
  padding-left: 2.5rem; }

.TipBox_pl-30__sCzSk {
  padding-left: 3rem; }

.TipBox_pr-5__1fb9v {
  padding-right: .5rem; }

.TipBox_pr-10__3VrEJ {
  padding-right: 1rem; }

.TipBox_pr-15__3fDJu {
  padding-right: 1.5rem; }

.TipBox_pr-20__1jle4 {
  padding-right: 2rem; }

.TipBox_pr-25__2uUG- {
  padding-right: 2.5rem; }

.TipBox_pr-30__3N-rs {
  padding-right: 3rem; }

html, body, #TipBox_root__3dKat {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.TipBox_background_gradient__1_s2x, .TipBox_tipBox_wrapper__3BXyB {
  height: 100%; }
  .TipBox_background_gradient__gray__VhnaU, .TipBox_tipBox_wrapper__3BXyB {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .TipBox_background_gradient__purpure__3K9Yl {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.TipBox_card__active__1xgfJ, .TipBox_tipBox_types__active__1taYR {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipBox_card__activeRotate__3cgpP {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipBox_card__disabled__34jcT {
  border: 2px solid #CDD8EF !important; }
  .TipBox_card__disabled__34jcT > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.TipBox_tipBox_title__3nz0- {
  font-size: 2.4rem;
  margin-top: 0.9rem; }

.TipBox_tipBox_subTitle__1CNBO {
  margin-top: .5rem;
  font-size: 1.4rem; }

.TipBox_tipBox_period__3feP6 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .7rem; }

.TipBox_tipBox_typesWrapper__3pJL6 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .8rem;
  margin: 2rem 0 3.5rem; }

.TipBox_tipBox_types__JOsFL {
  width: 100%;
  padding: 1.1rem 0;
  border-radius: .7rem;
  background: white;
  box-shadow: .1rem .3rem .6rem 0px #ccc; }
  .TipBox_tipBox_types__active__1taYR {
    font-weight: 600 !important;
    border: none !important; }
    .TipBox_tipBox_types__active__1taYR > span {
      color: white; }

.TipBox_tipBox_typesItem__1sp2G {
  font-size: 1.6rem;
  color: #404040; }

.TipBox_tipBox_dateList__39OKY {
  font-size: 1.4rem; }

.TipBox_tipBox_dateTitle__2ZS_C {
  font-weight: 700; }

.TipBox_tipBox_dateTable__1P_44 {
  width: 100%; }

.TipBox_tipBox_dateTableValue__1izuB {
  font-weight: 700;
  text-align: right; }

.TipBox_tipBox_dateTableRow__3mqrI {
  line-height: 2rem; }

/* Global variables */
.Boxes_boxes_wrapper__1aaEC {
  display: grid; }

.Boxes_boxes_listBox__xPemC {
  margin-top: 3.2rem;
  display: grid; }

.Boxes_boxes_list__39nyz {
  padding-left: 0;
  font-size: 1.4rem;
  list-style-type: none; }

.Boxes_boxes_listItem__2gS1g {
  display: grid;
  grid-template-columns: repeat(3, -webkit-max-content);
  grid-template-columns: repeat(3, max-content);
  margin-bottom: 1rem; }
  .Boxes_boxes_listItem__2gS1g > img {
    margin-right: 1.5rem; }
  .Boxes_boxes_listItem__2gS1g strong {
    font-size: 1.4rem; }

/* Global variables */
.Invite_invite_wrapper__7UxvT {
  display: grid;
  align-items: center;
  font-size: 1.4rem; }

.Invite_invite_qrCodeWrapper__2IY0t {
  text-align: center;
  margin-top: 2rem; }

.Invite_invite_qrcode__yPpbj {
  width: 15rem; }

/* Global variables */
.Create_container__D6TYL {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.Create_title__1IGo6 {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .Create_title_log__1tg7R {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .Create_title__small__Ze3H_ {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .Create_title__smallBold__3BXji {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .Create_title__marginTitle__1pVoi {
    margin: 2.2rem 0 1rem; }
  .Create_title__err__O5S9j {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.Create_inputValue__hzS4Z {
  font-weight: 700; }
  .Create_inputValue__int__1EXYF {
    font-size: 3rem; }
  .Create_inputValue__cent__qmTIL {
    font-size: 2.2rem; }
  .Create_inputValue__currency__2ITU0 {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.Create_mt-5__1vRxf {
  margin-top: .5rem; }

.Create_mt-10__3vk3H {
  margin-top: 1rem; }

.Create_mt-15__KvwyD {
  margin-top: 1.5rem; }

.Create_mt-20__3nq10 {
  margin-top: 2rem; }

.Create_mt-25__3_E_V {
  margin-top: 2.5rem; }

.Create_mt-30__ahF4K {
  margin-top: 3rem; }

.Create_mb-5__36oKp {
  margin-bottom: .5rem; }

.Create_mb-10__2Yfkh {
  margin-bottom: 1rem; }

.Create_mb-15__2913r {
  margin-bottom: 1.5rem; }

.Create_mb-20__1eR4b {
  margin-bottom: 2rem; }

.Create_mb-25__2rAPl {
  margin-bottom: 2.5rem; }

.Create_mb-30__2dfVY {
  margin-bottom: 3rem; }

.Create_ml-5__1AFeO {
  margin-left: .5rem; }

.Create_ml-10__2QbJN {
  margin-left: 1rem; }

.Create_ml-15__cPcp9 {
  margin-left: 1.5rem; }

.Create_ml-20__2o_I2 {
  margin-left: 2rem; }

.Create_ml-25__2JJhx {
  margin-left: 2.5rem; }

.Create_ml-30__hFKNR {
  margin-left: 3rem; }

.Create_mr-5__tzGv- {
  margin-right: .5rem; }

.Create_mr-10__1222i {
  margin-right: 1rem; }

.Create_mr-15__2iPKw {
  margin-right: 1.5rem; }

.Create_mr-20__2P-_i {
  margin-right: 2rem; }

.Create_mr-25__s09Is {
  margin-right: 2.5rem; }

.Create_mr-30__dBPUz {
  margin-right: 3rem; }

.Create_pt-5__2IhLh {
  padding-top: .5rem; }

.Create_pt-10__1yeD7 {
  padding-top: 1rem; }

.Create_pt-15__d1xAX {
  padding-top: 1.5rem; }

.Create_pt-20__2Kodg {
  padding-top: 2rem; }

.Create_pt-25__CbpkH {
  padding-top: 2.5rem; }

.Create_pt-30__2RQtt {
  padding-top: 3rem; }

.Create_pb-5__2hHlk {
  padding-bottom: .5rem; }

.Create_pb-10__c7FPG {
  padding-bottom: 1rem; }

.Create_pb-15__1L99_ {
  padding-bottom: 1.5rem; }

.Create_pb-20__3q7Hf {
  padding-bottom: 2rem; }

.Create_pb-25__1VL5F {
  padding-bottom: 2.5rem; }

.Create_pb-30__2RbLT {
  padding-bottom: 3rem; }

.Create_pl-5__3aVwo {
  padding-left: .5rem; }

.Create_pl-10__15NHz {
  padding-left: 1rem; }

.Create_pl-15__mzaa6 {
  padding-left: 1.5rem; }

.Create_pl-20__2g8I8 {
  padding-left: 2rem; }

.Create_pl-25__cqEaq {
  padding-left: 2.5rem; }

.Create_pl-30__2LTeq {
  padding-left: 3rem; }

.Create_pr-5__2QDOE {
  padding-right: .5rem; }

.Create_pr-10__BlScr {
  padding-right: 1rem; }

.Create_pr-15__Vjw7n {
  padding-right: 1.5rem; }

.Create_pr-20__3ZoNZ {
  padding-right: 2rem; }

.Create_pr-25__1CBPb {
  padding-right: 2.5rem; }

.Create_pr-30__2qSKD {
  padding-right: 3rem; }

html, body, #Create_root__1ixIn {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Create_background_gradient__2xN5Z {
  height: 100%; }
  .Create_background_gradient__gray__1XLCI {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .Create_background_gradient__purpure__3jrhv {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.Create_card__active__19-po, .Create_create_paymentSectionButton__TPp7d {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Create_card__activeRotate__eZSoT, .Create_create_paymentSectionButton__active__3jjKz {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Create_card__disabled__gcDWo {
  border: 2px solid #CDD8EF !important; }
  .Create_card__disabled__gcDWo > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.Create_create_wrapper__3tThQ {
  display: grid;
  grid-template-rows: auto; }

.Create_create_paymentSectionButton__active__3jjKz {
  font-weight: 600 !important;
  border: none !important; }

.Create_create_paymentSectionButton__disabled__v51k0 {
  border-color: #CDD8EF !important; }
  .Create_create_paymentSectionButton__disabled__v51k0 > span {
    color: #CDD8EF !important;
    font-weight: 600; }

/* Global variables */
.InfoBox_InfoBox_wrapper__cR57X {
  display: grid;
  align-items: center;
  grid-template-columns: 30px 1fr; }

.InfoBox_InfoBox_img__1cGwQ {
  align-self: baseline; }

.InfoBox_InfoBox_text__aJKwe {
  margin: 0;
  font-size: 1.4rem;
  text-align: left; }

/* Global variables */
.Balance_container__1uZok {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.Balance_title__2QRvg {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .Balance_title_log__OX-bG {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .Balance_title__small__1EqJp {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .Balance_title__smallBold__3tOYZ {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .Balance_title__marginTitle__2-8zt {
    margin: 2.2rem 0 1rem; }
  .Balance_title__err__3yoga {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.Balance_inputValue__3-pIh {
  font-weight: 700; }
  .Balance_inputValue__int__1DWjs {
    font-size: 3rem; }
  .Balance_inputValue__cent__3ueNv {
    font-size: 2.2rem; }
  .Balance_inputValue__currency__2qxlT {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.Balance_mt-5__2YaOR {
  margin-top: .5rem; }

.Balance_mt-10__3u0Uq {
  margin-top: 1rem; }

.Balance_mt-15__3AO3U {
  margin-top: 1.5rem; }

.Balance_mt-20__2_D7K {
  margin-top: 2rem; }

.Balance_mt-25__1wf6t {
  margin-top: 2.5rem; }

.Balance_mt-30__3NgtC {
  margin-top: 3rem; }

.Balance_mb-5__2gMT1 {
  margin-bottom: .5rem; }

.Balance_mb-10__3ERxr {
  margin-bottom: 1rem; }

.Balance_mb-15__Nm-aI {
  margin-bottom: 1.5rem; }

.Balance_mb-20__15vh_ {
  margin-bottom: 2rem; }

.Balance_mb-25__13-3m {
  margin-bottom: 2.5rem; }

.Balance_mb-30__1oZp_ {
  margin-bottom: 3rem; }

.Balance_ml-5__T0FnW {
  margin-left: .5rem; }

.Balance_ml-10__2iOWL {
  margin-left: 1rem; }

.Balance_ml-15__2OHbF {
  margin-left: 1.5rem; }

.Balance_ml-20__3jgnb {
  margin-left: 2rem; }

.Balance_ml-25__3Cast {
  margin-left: 2.5rem; }

.Balance_ml-30__1_hQ7 {
  margin-left: 3rem; }

.Balance_mr-5__1erwc {
  margin-right: .5rem; }

.Balance_mr-10__3ez_X {
  margin-right: 1rem; }

.Balance_mr-15__1WWDJ {
  margin-right: 1.5rem; }

.Balance_mr-20__1HLDe {
  margin-right: 2rem; }

.Balance_mr-25__JV5ve {
  margin-right: 2.5rem; }

.Balance_mr-30__3bTSf {
  margin-right: 3rem; }

.Balance_pt-5__35A47 {
  padding-top: .5rem; }

.Balance_pt-10__WuiWt {
  padding-top: 1rem; }

.Balance_pt-15__1IAUd {
  padding-top: 1.5rem; }

.Balance_pt-20___QYxY {
  padding-top: 2rem; }

.Balance_pt-25__1OGvK {
  padding-top: 2.5rem; }

.Balance_pt-30__3d7D6 {
  padding-top: 3rem; }

.Balance_pb-5__2qgd6 {
  padding-bottom: .5rem; }

.Balance_pb-10__3gwWW {
  padding-bottom: 1rem; }

.Balance_pb-15__11hxy {
  padding-bottom: 1.5rem; }

.Balance_pb-20__3ek_o {
  padding-bottom: 2rem; }

.Balance_pb-25__1PoOU {
  padding-bottom: 2.5rem; }

.Balance_pb-30__1iieD {
  padding-bottom: 3rem; }

.Balance_pl-5__3kTmd {
  padding-left: .5rem; }

.Balance_pl-10__2eMHM {
  padding-left: 1rem; }

.Balance_pl-15__Ilq8W {
  padding-left: 1.5rem; }

.Balance_pl-20__t2_Is {
  padding-left: 2rem; }

.Balance_pl-25__gBuTf {
  padding-left: 2.5rem; }

.Balance_pl-30__3pKyW {
  padding-left: 3rem; }

.Balance_pr-5__2O6rU {
  padding-right: .5rem; }

.Balance_pr-10__2gKNU {
  padding-right: 1rem; }

.Balance_pr-15__2SfbL {
  padding-right: 1.5rem; }

.Balance_pr-20__1iYvB {
  padding-right: 2rem; }

.Balance_pr-25__2OK5d {
  padding-right: 2.5rem; }

.Balance_pr-30__2av44 {
  padding-right: 3rem; }

html, body, #Balance_root__1dfNl {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Balance_background_gradient__2FQXn, .Balance_balance_wrapper__mfAzo {
  height: 100%; }
  .Balance_background_gradient__gray__tCM3D, .Balance_balance_wrapper__mfAzo {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .Balance_background_gradient__purpure__3vNN6 {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.Balance_card__active__1GKz4 {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Balance_card__activeRotate__21-t7 {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Balance_card__disabled__10fKx {
  border: 2px solid #CDD8EF !important; }
  .Balance_card__disabled__10fKx > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.Balance_balance_title__21kb8 {
  font-size: 2.4rem;
  margin-top: 0.9rem; }

.Balance_balance_subTitle__3VLRr {
  margin-top: .5rem;
  font-size: 1.4rem; }

.Balance_balance_tip__1XfWv {
  display: grid;
  text-align: center; }

.Balance_balance_infobox__1BE8U {
  margin-top: 3rem;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2rem; }
  .Balance_balance_infobox__1BE8U > button {
    border: none; }

.Balance_balance_editProfile__1nGES {
  display: block;
  text-align: left;
  margin: 2rem auto 0 3rem;
  font-size: 1.6rem;
  color: #404040; }

.Balance_balance_thankYouAddText__2bV7a {
  margin-top: 0;
  font-size: 1.6rem; }

/* Global variables */
.Profile_container__1pJ9J {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.Profile_title__1XBFV {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .Profile_title_log__1zhVF {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .Profile_title__small__U_1Nc {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .Profile_title__smallBold__etrid {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .Profile_title__marginTitle__3WrsX {
    margin: 2.2rem 0 1rem; }
  .Profile_title__err__2t5UL {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.Profile_inputValue__INs0_ {
  font-weight: 700; }
  .Profile_inputValue__int__2JjBs {
    font-size: 3rem; }
  .Profile_inputValue__cent__2G8Po {
    font-size: 2.2rem; }
  .Profile_inputValue__currency__1sxXo {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.Profile_mt-5__w7Nfy {
  margin-top: .5rem; }

.Profile_mt-10__1FCVc {
  margin-top: 1rem; }

.Profile_mt-15__2hK2P {
  margin-top: 1.5rem; }

.Profile_mt-20__2l3ym {
  margin-top: 2rem; }

.Profile_mt-25__2u1OS {
  margin-top: 2.5rem; }

.Profile_mt-30__30pr9 {
  margin-top: 3rem; }

.Profile_mb-5__1aYtH {
  margin-bottom: .5rem; }

.Profile_mb-10__203lD {
  margin-bottom: 1rem; }

.Profile_mb-15__13bIe {
  margin-bottom: 1.5rem; }

.Profile_mb-20__4bfFg {
  margin-bottom: 2rem; }

.Profile_mb-25__3Wbev {
  margin-bottom: 2.5rem; }

.Profile_mb-30__25i15 {
  margin-bottom: 3rem; }

.Profile_ml-5__3DHVK {
  margin-left: .5rem; }

.Profile_ml-10__3lQrQ {
  margin-left: 1rem; }

.Profile_ml-15__1f_Ay {
  margin-left: 1.5rem; }

.Profile_ml-20__3poop {
  margin-left: 2rem; }

.Profile_ml-25__1PIx0 {
  margin-left: 2.5rem; }

.Profile_ml-30__tfKtC {
  margin-left: 3rem; }

.Profile_mr-5__3M9OD {
  margin-right: .5rem; }

.Profile_mr-10__30tp_ {
  margin-right: 1rem; }

.Profile_mr-15__13B0x {
  margin-right: 1.5rem; }

.Profile_mr-20__d_O6x {
  margin-right: 2rem; }

.Profile_mr-25__gFH1z {
  margin-right: 2.5rem; }

.Profile_mr-30__2TmAu {
  margin-right: 3rem; }

.Profile_pt-5__1XnjZ {
  padding-top: .5rem; }

.Profile_pt-10__3sswM {
  padding-top: 1rem; }

.Profile_pt-15__1Iopw {
  padding-top: 1.5rem; }

.Profile_pt-20__ZBsEd {
  padding-top: 2rem; }

.Profile_pt-25__1Z-Ge {
  padding-top: 2.5rem; }

.Profile_pt-30__2qtg8 {
  padding-top: 3rem; }

.Profile_pb-5__Jawk- {
  padding-bottom: .5rem; }

.Profile_pb-10__x5QYf {
  padding-bottom: 1rem; }

.Profile_pb-15__3lj2G {
  padding-bottom: 1.5rem; }

.Profile_pb-20__29PSv {
  padding-bottom: 2rem; }

.Profile_pb-25__36fnC {
  padding-bottom: 2.5rem; }

.Profile_pb-30__3fQW7 {
  padding-bottom: 3rem; }

.Profile_pl-5__-oCLX {
  padding-left: .5rem; }

.Profile_pl-10__1Rt9O {
  padding-left: 1rem; }

.Profile_pl-15__opsyY {
  padding-left: 1.5rem; }

.Profile_pl-20__2UtZ5 {
  padding-left: 2rem; }

.Profile_pl-25__1dAID {
  padding-left: 2.5rem; }

.Profile_pl-30__8jsPg {
  padding-left: 3rem; }

.Profile_pr-5__1AWTj {
  padding-right: .5rem; }

.Profile_pr-10__Q-R97 {
  padding-right: 1rem; }

.Profile_pr-15__2qTXO {
  padding-right: 1.5rem; }

.Profile_pr-20__1I826 {
  padding-right: 2rem; }

.Profile_pr-25__WVcPX {
  padding-right: 2.5rem; }

.Profile_pr-30__2NrMV {
  padding-right: 3rem; }

html, body, #Profile_root__qal4u {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Profile_background_gradient__1xjpk, .Profile_profile_wrapper__T_OsE {
  height: 100%; }
  .Profile_background_gradient__gray__yIH2S, .Profile_profile_wrapper__T_OsE {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .Profile_background_gradient__purpure__Ydh09 {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.Profile_card__active__3ESPW {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Profile_card__activeRotate__3QhDE {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.Profile_card__disabled__5ykE2 {
  border: 2px solid #CDD8EF !important; }
  .Profile_card__disabled__5ykE2 > span {
    color: #CDD8EF !important;
    font-weight: 600; }

/* Global variables */
.Profile_profile_container__rFkrz {
  display: grid;
  font-size: 1.4rem; }

.Profile_profile_title__322wO {
  font-size: 2.4rem;
  margin-top: 0.9rem; }

.Profile_profile_subTitle__1AGsm {
  font-size: 1.8rem;
  margin: 2.3rem 0 2rem 0; }

.Profile_profile_readOnlyData___6XmT {
  margin: 2rem 0; }
  .Profile_profile_readOnlyData__label__22CVl {
    margin-bottom: .5rem; }
  .Profile_profile_readOnlyData__text__3KUrn {
    color: #AFAFAF;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80vw; }

.Profile_profile_formControl__Am3aG {
  margin-bottom: 2rem; }

/* Global variables */
.TipBoxInvite_container__3btAQ {
  display: grid;
  padding: 1.7rem 2.8rem 0; }

.TipBoxInvite_title__3rvt5 {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem; }
  .TipBoxInvite_title_log__2pYeL {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 2rem 0 2.5rem 0;
    text-align: center; }
  .TipBoxInvite_title__small__3slGh {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: 400; }
  .TipBoxInvite_title__smallBold__2jzQf {
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0.5rem; }
  .TipBoxInvite_title__marginTitle__1pC7J {
    margin: 2.2rem 0 1rem; }
  .TipBoxInvite_title__err__3Xj4J {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center; }

.TipBoxInvite_inputValue__2yl3u {
  font-weight: 700; }
  .TipBoxInvite_inputValue__int__1SDzC {
    font-size: 3rem; }
  .TipBoxInvite_inputValue__cent__2UyW3 {
    font-size: 2.2rem; }
  .TipBoxInvite_inputValue__currency__3oZlW {
    font-weight: 1.4rem;
    margin-left: .5rem; }

.TipBoxInvite_mt-5__YNpqv {
  margin-top: .5rem; }

.TipBoxInvite_mt-10__3EdBm {
  margin-top: 1rem; }

.TipBoxInvite_mt-15__11bxx {
  margin-top: 1.5rem; }

.TipBoxInvite_mt-20__35rme {
  margin-top: 2rem; }

.TipBoxInvite_mt-25__313wl {
  margin-top: 2.5rem; }

.TipBoxInvite_mt-30__2Ozht {
  margin-top: 3rem; }

.TipBoxInvite_mb-5__2R2Y4 {
  margin-bottom: .5rem; }

.TipBoxInvite_mb-10__36jb3 {
  margin-bottom: 1rem; }

.TipBoxInvite_mb-15__1OdOB {
  margin-bottom: 1.5rem; }

.TipBoxInvite_mb-20__3pN6E {
  margin-bottom: 2rem; }

.TipBoxInvite_mb-25__39qcm {
  margin-bottom: 2.5rem; }

.TipBoxInvite_mb-30__3EHS_ {
  margin-bottom: 3rem; }

.TipBoxInvite_ml-5__38rsi {
  margin-left: .5rem; }

.TipBoxInvite_ml-10__1FAIy {
  margin-left: 1rem; }

.TipBoxInvite_ml-15__3Rjig {
  margin-left: 1.5rem; }

.TipBoxInvite_ml-20__D7oBc {
  margin-left: 2rem; }

.TipBoxInvite_ml-25__gdFSy {
  margin-left: 2.5rem; }

.TipBoxInvite_ml-30__3TjnH {
  margin-left: 3rem; }

.TipBoxInvite_mr-5__140hU {
  margin-right: .5rem; }

.TipBoxInvite_mr-10__X26Wh {
  margin-right: 1rem; }

.TipBoxInvite_mr-15__2wJ0A {
  margin-right: 1.5rem; }

.TipBoxInvite_mr-20__2yeCj {
  margin-right: 2rem; }

.TipBoxInvite_mr-25__Qckgj {
  margin-right: 2.5rem; }

.TipBoxInvite_mr-30__3sMdY {
  margin-right: 3rem; }

.TipBoxInvite_pt-5__21e3G {
  padding-top: .5rem; }

.TipBoxInvite_pt-10__32o87 {
  padding-top: 1rem; }

.TipBoxInvite_pt-15__3XR22 {
  padding-top: 1.5rem; }

.TipBoxInvite_pt-20__3nuU4 {
  padding-top: 2rem; }

.TipBoxInvite_pt-25__1nnLz {
  padding-top: 2.5rem; }

.TipBoxInvite_pt-30__1WKOj {
  padding-top: 3rem; }

.TipBoxInvite_pb-5__3efw7 {
  padding-bottom: .5rem; }

.TipBoxInvite_pb-10__3oSBA {
  padding-bottom: 1rem; }

.TipBoxInvite_pb-15__B6wet {
  padding-bottom: 1.5rem; }

.TipBoxInvite_pb-20__w0dbR {
  padding-bottom: 2rem; }

.TipBoxInvite_pb-25__35Va9 {
  padding-bottom: 2.5rem; }

.TipBoxInvite_pb-30__1QCMZ {
  padding-bottom: 3rem; }

.TipBoxInvite_pl-5__1Zd08 {
  padding-left: .5rem; }

.TipBoxInvite_pl-10__121af {
  padding-left: 1rem; }

.TipBoxInvite_pl-15__1zpBY {
  padding-left: 1.5rem; }

.TipBoxInvite_pl-20__1Il8Q {
  padding-left: 2rem; }

.TipBoxInvite_pl-25__3keG7 {
  padding-left: 2.5rem; }

.TipBoxInvite_pl-30__2SJ_n {
  padding-left: 3rem; }

.TipBoxInvite_pr-5__3q73Q {
  padding-right: .5rem; }

.TipBoxInvite_pr-10__1GR8L {
  padding-right: 1rem; }

.TipBoxInvite_pr-15__1iRTF {
  padding-right: 1.5rem; }

.TipBoxInvite_pr-20__1NFwB {
  padding-right: 2rem; }

.TipBoxInvite_pr-25__3r0p8 {
  padding-right: 2.5rem; }

.TipBoxInvite_pr-30__1MII_ {
  padding-right: 3rem; }

html, body, #TipBoxInvite_root__1zj0_ {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: #404040;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.TipBoxInvite_background_gradient__PwpdH, .TipBoxInvite_tipBoxInvite_wrapper__3mlAk {
  height: 100%; }
  .TipBoxInvite_background_gradient__gray__2gq-a, .TipBoxInvite_tipBoxInvite_wrapper__3mlAk {
    background: -webkit-gradient(linear, left top, left bottom, from(#DDD7E6), to(#FEFEFE));
    background: linear-gradient(#DDD7E6, #FEFEFE); }
  .TipBoxInvite_background_gradient__purpure__2wwA3 {
    background: -webkit-gradient(linear, left top, left bottom, from(#2B0096), to(#E9649B));
    background: linear-gradient(#2B0096, #E9649B); }

.TipBoxInvite_card__active__3cgOn {
  background: linear-gradient(130deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipBoxInvite_card__activeRotate__RSs25 {
  background: linear-gradient(260deg, #E9649B, #2B0096) !important;
  color: #fff !important; }

.TipBoxInvite_card__disabled__1BKK9 {
  border: 2px solid #CDD8EF !important; }
  .TipBoxInvite_card__disabled__1BKK9 > span {
    color: #CDD8EF !important;
    font-weight: 600; }

.TipBoxInvite_tipBoxInvite_header__36m7N {
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  margin: 4rem 3rem;
  line-height: 1.4; }

.TipBoxInvite_tipBoxInvite_spinner__2xYFX {
  display: grid;
  margin-top: 2rem; }

/* Global variables */
.App_header_header__39qXM {
  background-color: white;
  font-family: 'Montserrat', sans-serif; }
  .App_header_header_logo__VsEWu {
    text-decoration: none;
    cursor: pointer; }
  .App_header_header_logoTitle__Uv0lQ {
    font-size: 2.2rem;
    font-weight: 700;
    background: linear-gradient(118deg, #2B0096, #E9649B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .App_header_header_menuItems__2p2Aq {
    list-style: none;
    margin-top: 6rem;
    padding-left: 3.5rem; }
    .App_header_header_menuItems__item__2fbhN {
      margin: 2.2rem 0; }
      .App_header_header_menuItems__item__2fbhN:last-child {
        margin: 4rem 0; }
        .App_header_header_menuItems__item__2fbhN:last-child .App_header_header_menuItems__link__3VYh- {
          color: #E9649B; }
    .App_header_header_menuItems__link__3VYh- {
      color: #404040;
      text-decoration: none;
      font-size: 1.8rem; }
    .App_header_header_menuItems__linkActive__3q4op {
      font-weight: 700; }
  .App_header_header_switchButton__zAl8b {
    position: relative;
    margin-left: auto;
    margin-right: 2rem; }
    .App_header_header_switchButton__icon__26y9Z {
      position: absolute;
      top: .9rem;
      z-index: 2; }
    .App_header_header_switchButton__activeSloik__21rXv {
      position: absolute;
      bottom: -1rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      color: black;
      font-size: .8rem;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 5.2rem; }
  .App_header_header_modal__tgyo0 > div:nth-child(3) > div {
    align-self: baseline;
    border: 2px solid #AA4399;
    border-radius: 10px; }
    .App_header_header_modal__tgyo0 > div:nth-child(3) > div > div:nth-child(1) > div:nth-child(1) {
      margin-bottom: 1rem;
      font-weight: 700;
      color: #AA4399; }
    .App_header_header_modal__tgyo0 > div:nth-child(3) > div > div:nth-child(1) > p {
      margin-top: 2rem;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.4rem; }
    .App_header_header_modal__tgyo0 > div:nth-child(3) > div > div:nth-child(2) {
      margin-bottom: 2rem;
      margin-right: auto;
      padding: .8rem 2.4rem;
      font-size: 1.4rem; }
  .App_header_header_modal__close__2r2xL {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer; }

