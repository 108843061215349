@import 'style/_variables.scss';

.login {
  &_wrapper {
    color: white;
    position: relative;
    overflow: auto;
  }
  &_form {
    justify-items: center;
    align-items: start;
    grid-template-rows: repeat(6, max-content) minmax(3.5rem, 6rem);

    @include webkitInput;
  }

  &_resetLink {
    font-size: 1.4rem;
    color: white;
    justify-self: end;
  }
  &_signupText, &_signupLink {
    font-size: 1.4rem;
    line-height: 1.5;
    color: white;
    justify-self: start;
    align-self: end;
    text-decoration: none;
    &__nowrap {
      white-space: nowrap;
    }
  }
  &_signupLink {
    text-decoration: underline;
    font-weight: $font-bold;
    white-space: nowrap;
  }
  &_facebookIcon {
    position: relative;
    top: -1px;
    height: 1.7rem;
    color: white;
  }
}