@import 'style/main.scss';
@import 'style/_variables.scss';

.profile {
  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_container {
    display: grid;
    font-size: 1.4rem;
  }

  &_title {
    font-size: 2.4rem;
    margin-top: 0.9rem;
  }

  &_subTitle {
    font-size: 1.8rem;
    margin: 2.3rem 0 2rem 0;
  }
  &_readOnlyData {
    margin: 2rem 0;
    &__label {
      margin-bottom: .5rem;
    }
    &__text {
      color: $text-grey-lighten;
      font-weight: $font-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80vw;
    }
  }

  &_formControl {
    margin-bottom: 2rem;
  }
}