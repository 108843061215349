/* Global variables */
$content-padding-top: 1.7rem;
$content-padding-horizontal: 2.8rem;

$bg-gradient-top-gray: #DDD7E6;
$bg-gradient-bottom-gray: #FEFEFE;
$bg-gradient-top-purpure: #2B0096;
$bg-gradient-bottom-purpure: #E9649B;
$blue-disabling: #CDD8EF;
$text-white: #fff;
$text-grey: #404040;
$text-grey-lighten: #AFAFAF;
$text-grey-disabled: #CDD8EF;
$grey-disabling: #e6e6e6;
$text-purple: #AA4399;
// Font weights are determinate by Montserrat Google Font styles
$font-regular: 400;
$font-semiBold: 600;
$font-bold: 700;

$appbar-height: 5rem; // In App_header.tsx
$height-without-appbar: calc(100% - #{$appbar-height}); 

// Mixins
@mixin webkitInput {
  input {
    &:-webkit-autofill,
    -webkit-autofill:hover,
    -webkit-autofill:focus,
    -webkit-autofill:active {
      border: 2px solid white;
      border-radius: .6rem;
      -webkit-text-fill-color: black;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
  }
}

@mixin placeholder-blik {
  position: relative;
  top: -2px;
  font-weight: $font-regular;
  font-size: 1.4rem;
  text-align: left;
  letter-spacing: normal;
  line-height: 2.2;
}