@import 'style/main.scss';

.newTip {

  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_formTip {
    position: absolute;
    left: -1000rem;
    margin-top: 1.0rem;
    max-height: .01rem;
    background: transparent;
    overflow: hidden;
    margin: 0;
    z-index: -1;

    label {
      margin-bottom: 1.6rem !important;
      font-size: 1.8rem !important;
      font-weight: $font-bold;
    }

    input {
      text-align: center;
      font-size: 2.4rem;
      padding: .6rem 1.4rem !important;
    }
  }

  &_titleTip {
    height: 40px;
    width: 100%;
    font-size: 2.6rem;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background: white;
  }

  &_subTitle {
    margin-top: .5rem;
    font-size: 1.4rem;
  }

  &_cardBoxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .8rem;
  }

  &_payment {
    height: 20rem;
  }

  &_paymentSection {
    margin-top: 2.5rem;
  }

  &_paymentSectionButton {
    @extend .card__active;
    border: none !important;
    
    &__active {
      @extend .card__activeRotate;
      font-weight: $font-semiBold !important;
      border: none !important;
    }
  
    &__disabled {
      border: 2px solid $text-grey-disabled !important;
      
      > span {
        color: $text-grey-disabled !important;
        font-weight: $font-semiBold;
      }
    }
  }

  &_paymentSectionForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      grid-column: 1 / -1;
    }
  }

  &_paymentSectionBlikCode {
    input {
      &::placeholder { @include placeholder-blik; }
    }
  }

  &_paymentSectionTipCode {
    text-align: center;
  }

  &_paymentSectionTipCodeWrapperImg {
    height: 90px;
    margin-top: .9rem;
  }

  &_newTip_paymentSectionTipCodeWrapperImg {
    height: 100%;
  }

  &_spinner {
    &__small {
      display: grid;
      align-items: center;
      height: 15rem;
    }
  }

}