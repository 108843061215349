@import 'style/_variables.scss';

.signUp {
  &_wrapper {
    color: white;
    position: relative;
    overflow: auto;
  }
  &_form {
    justify-items: center;
    align-items: start;
    grid-template-rows: repeat(5, max-content) minmax(2rem, 4.5rem);

    @include webkitInput;
  }

  &_terms {
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    font-size: 1.4rem;
    margin: 2rem 0 1rem 0;
  }
  &_termsLink {
    font-size: 1.4rem;
    color: white;
    justify-self: end;
    text-decoration: underline;
    cursor: pointer;
  }
  &_loginText, &_loginLink {
    font-size: 1.4rem;
    color: white;
    justify-self: start;
    align-self: end;
    text-decoration: none;
  }
  &_loginLink {
    text-decoration: underline;
    font-weight: $font-bold;
  }
  &_termsTitle {
    margin: 3rem 1.8rem;
    font-weight: $font-bold;
    grid-area: title;
    font-size: 1.8rem;
    width: calc(83vw - 6rem);
  }
  &_termsText {
    margin: 0 2.5rem 2rem 2rem;
    font-size: 1.4rem;
    line-height: 1.5;
    grid-area: terms;
    :first-child {
      margin-top: 0;
    }
    &__paragraph {
      margin-right: 3.5rem;
    }
  }
}