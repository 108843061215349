.priceInputFake {
  &_titleTip {
    height: 40px;
    width: 100%;
    font-size: 2.6rem;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background: white;
    box-sizing: border-box;
  }
}