@import 'style/_variables.scss';

.boxes {
  &_wrapper {
    display: grid;
  }

  &_listBox {
    margin-top: 3.2rem;
    display: grid;
  }

  &_list {
    padding-left: 0;
    font-size: 1.4rem;
    list-style-type: none;
  }

  &_listItem {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    margin-bottom: 1rem;

    > img {
      margin-right: 1.5rem;
    }

    strong {
      font-size: 1.4rem;
    }
  }
  
}