@import 'style/_variables.scss';

.lds-spinner-small {
  justify-self: center;
  width: 64px;
  height: 64px;
}
.lds-spinner-small div {
  transform-origin: 32px 32px;
  animation: lds-spinner-small 1.2s linear infinite;
}
.lds-spinner-small div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 30px;
  width: 4px;
  height: 20px;
  border-radius: 25%;
  background: linear-gradient($bg-gradient-bottom-purpure, $bg-gradient-top-purpure);
}
.lds-spinner-small div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-small div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner-small div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner-small div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner-small div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner-small div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner-small div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-small div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner-small div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner-small div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner-small div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner-small div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner-small {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
