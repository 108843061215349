@import 'style/_variables.scss';

.switchWrapper {
  position: relative;
  height: $height-without-appbar;
}
 
.switchWrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}