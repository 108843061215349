@import 'style/main.scss';

.tipBox {
  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_title {
    font-size: 2.4rem;
    margin-top: 0.9rem;
  }
  
  &_subTitle {
    margin-top: .5rem;
    font-size: 1.4rem;
  }

  &_period {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .7rem;
  }
  
  &_typesWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .8rem;
    margin: 2rem 0 3.5rem;
  }

  &_types {
    width: 100%;
    padding: 1.1rem 0;
    border-radius: .7rem;
    background: white;
    box-shadow: .1rem .3rem .6rem 0px #ccc;
    
    &__active {
      @extend .card__active;
      font-weight: $font-semiBold !important;
      border: none !important;

      > span {
        color: white;
      }
    }
  }
  
  &_typesItem {
    font-size: 1.6rem;
    color: $text-grey;
  }


  &_dateList {
    font-size: 1.4rem;
  }

  &_dateTitle {
    font-weight: $font-bold;
  }

  &_dateTable {
    width: 100%;
  }
  
  &_dateTableValue {
    font-weight: $font-bold;
    text-align: right;
  }

  &_dateTableRow {
    line-height: 2rem;
  }
}