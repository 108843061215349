@import 'style/main.scss';

.create {
  &_wrapper {
    display: grid;
    grid-template-rows: auto;
  }

  &_paymentSectionButton {
    @extend .card__active;
    
    &__active {
      @extend .card__activeRotate;
      font-weight: $font-semiBold !important;
      border: none !important;
    }
  
    &__disabled {
      border-color: $text-grey-disabled !important;
      
      > span {
        color: $text-grey-disabled !important;
        font-weight: $font-semiBold;
      }
    }
  }
}