@import 'style/_variables.scss';

.logout {
  display: grid;
  grid-template-rows: repeat(2, max-content) auto;
  justify-items: center;
  align-items: end;
  &_jar {
    margin: 15vh 1.5rem 0 0;
    width: auto;
    height: 82px;
  }

  &_title {
    font-size: 2.4rem;
    font-weight: $font-bold;
    margin: 2.5rem 0 4rem 0;
    text-align: center;
  }

  &_logoutLink {
    color: $text-grey;
    font-size: 1.6rem;
    align-self: start;
  }
}
