@import 'style/_variables.scss';

.invite {
  &_wrapper {
    display: grid;
    align-items: center;
    font-size: 1.4rem;
  }
  &_qrCodeWrapper {
    text-align: center;
    margin-top: 2rem;
  }
  &_qrcode {
    width: 15rem;
  }
}