@import 'style/main.scss';

.container {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  text-align: center;

  
  h1 {
    font-size: 2.4rem;
    padding: 0 2rem;
  }
}

.images {
  display: grid;
  justify-content: center;
  max-width: 20rem;
  height: 17rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);


  &_bg {
    position: absolute;
    bottom: 0;
    height: 11.5rem;
    width: 100%;
    text-align: center;
    overflow: hidden;
    
    img {
      margin-top: -4.0rem;
      width: 100%;
    }
  }

  &_icon {
    height: 8.0rem;
    align-self: center;
    z-index: 2;
  }

}

.additionalText {
  font-size: 1.6rem;
  margin-bottom: 3rem;
}

.link {
  font-size: 1.8rem;;
  text-decoration: underline;
  cursor: pointer;
}