@import 'style/_variables.scss';

.reset {
  &_wrapper {
    color: white;
    position: relative;
    overflow: auto;
  }
  &_form {
    justify-items: center;
    align-items: start;
    grid-template-rows: repeat(4, max-content) minmax(4rem, auto);
    height: $height-without-appbar;

    @include webkitInput;
  }
  &_info {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &_loginLink {
    font-size: 1.2rem;
    font-weight: $font-bold;
    color: white;
    justify-self: start;
    align-self: end;
    margin-bottom: 2rem;
  }
}