@import 'style/main.scss';

.tipCodeGive {

  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_container {
    display: grid;
  }

  &_thankYou {
    text-align: left;

    > div {
      padding: 0;
    }

    &__title {
      margin-top: 0;
      text-align: left;
      font-weight: $font-regular;
    }

    &__text {
      margin-top: 2.3rem;
      text-align: left;
      font-size: 1.6rem;
    }

    button {
      border: none;
    }
  }

  &_title {
    font-size: 2.4rem;
    margin-top: 0.9rem;
  }

  &_subTitle {
    font-size: 1.6rem;
    margin-top: .5rem;
  }

  &_formTip {
    position: absolute;
    left: -1000rem;
    margin-top: 1.0rem;
    max-height: .01rem;
    background: transparent;
    overflow: hidden;
    margin: 0;
    z-index: -1;

    label {
      margin-bottom: 1.6rem !important;
      font-size: 1.8rem !important;
      font-weight: $font-bold;
    }

    input {
      text-align: center;
      font-size: 2.4rem;
      padding: .6rem 1.4rem !important;
    }
  }
  
  &_titleTip {
    height: 40px;
    width: 100%;
    font-size: 2.6rem;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background: white;
  }

  &_cardBoxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4.4rem;
    margin-bottom: 4rem;
  }

  &_gpayButton {
    // Override Google Pay styles
    button {
      width: 100% !important;
      height: 100%;
      box-shadow: none !important;
    }
  }

  &_blikCode {
    input {
      &::placeholder { @include placeholder-blik; }
    }
  }

  &_paymentItem {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    background: white;
    border: 2px dotted white;
    border-radius: .6rem;
    height: 6rem;
    box-sizing: border-box;

    &__selected {
      border: 2px solid $bg-gradient-top-purpure;
    }
  }

  &_paymentSectionButton {

    &__active {
      @extend .card__activeRotate;
      font-weight: $font-semiBold !important;
      border: none !important;
    }

    &__disabled {
      border: 2px solid $text-grey-disabled !important;
      
      > span {
        color: $text-grey-disabled !important;
        font-weight: $font-semiBold;
      }
    }
  }

  &_paymentTYP {
    > div {
      color: $text-grey;
      margin-top: -.5rem;
    }
  }

}