@import 'style/_variables.scss';

.terms {
  &_termsTitle {
    margin: 3rem 1.8rem;
    font-weight: $font-bold;
    grid-area: title;
    font-size: 1.8rem;
    width: calc(83vw - 6rem);
  }
  &_termsText {
    margin: 0 2.5rem 2rem 2rem;
    font-size: 1.4rem;
    line-height: 1.5;
    grid-area: terms;
    :first-child {
      margin-top: 0;
    }
    // InnerHTML styling
    & p {
      margin-right: 3.5rem;
    }
  }
}
