@import 'style/_variables.scss';

.header {
  background-color: white;
  font-family: 'Montserrat', sans-serif;

  &_logo {
    text-decoration: none;
    cursor: pointer;
  }
  &_logoTitle {
    font-size: 2.2rem;
    font-weight: $font-bold;
    background: linear-gradient(118deg, #2B0096, #E9649B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &_menuItems {
    list-style: none;
    margin-top: 6rem;
    padding-left: 3.5rem;
    &__item {
      margin: 2.2rem 0;
      &:last-child {
        margin: 4rem 0;
        .header_menuItems__link {
          color: #E9649B;
        }
      }
    }
    &__link {
      color: $text-grey;
      text-decoration: none;
      font-size: 1.8rem;
    }
    &__linkActive {
      font-weight: $font-bold;
    }
  }

  &_switchButton {
    position: relative;
    margin-left: auto;
    margin-right: 2rem;

    &__icon {
      position: absolute;
      top: .9rem;
      z-index: 2;
    }

    &__activeSloik {
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      color: black;
      font-size: .8rem;
      font-weight: $font-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 5.2rem;
    }
  }

  &_modal {
    > div:nth-child(3) > div {
      align-self: baseline;
      border: 2px solid $text-purple;
      border-radius: 10px;

      > div:nth-child(1) {

        > div:nth-child(1) {
          margin-bottom: 1rem;
          font-weight: $font-bold;
          color: $text-purple;
        }

        > p {
          margin-top: 2rem;
          font-family: 'Montserrat', sans-serif;
          font-size: 1.4rem;
        }
      }

      > div:nth-child(2) {
        margin-bottom: 2rem;
        margin-right: auto;
        padding: .8rem 2.4rem;
        font-size: 1.4rem;
      }
    }

    &__close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
    }
  }
}