@import 'style/_variables.scss';

.about {
  justify-items: left;
  align-items: end;
  margin: 0 auto;
  font-size: 1.4rem;
  
  &_smallTitle {
    font-size: 1.8rem;
    font-weight: $font-bold;
    margin: 1rem 0;
  }
  &_versionNumbers {
    display: grid;
    grid-template-columns: auto auto;
    &__margin {
      margin: 0 5rem 1rem 0;
    }
  }
  &_whatsNew {
    margin-bottom: 1rem;
    // InnerHTML styling
    div {
      &:last-child {
        margin: 2rem 0;
      }
    }
    a {
      color: black;
      font-weight: $font-semiBold;
    }
    p {
      margin: 0.3rem 0;
    }
  }
  &_termsLink {
    font-size: 1.6rem;
    color: $text-grey;
  }
}
