@import 'style/_variables.scss';

.ApplePay {
  &_paymentItem {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    background: white;
    border: 2px dotted white;
    border-radius: .6rem;
    height: 6rem;
    box-sizing: border-box;

    &__selected {
      border: 2px solid $bg-gradient-top-purpure;
    }
  }
}