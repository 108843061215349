@import 'style/main.scss';

.tipCode {

  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_container {
    display: grid;
  }

  &_title {
    font-size: 2.4rem;
    margin-top: 0.9rem;
  }

  &_subTitle {
    font-size: 1.6rem;
    margin-top: 2.3rem;
  }

  &_qrCodeWrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 140px;
  }

  &_printButtonsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 2rem 0;
    justify-items: center;
    column-gap: 1.8rem;
    &__link {
      text-decoration: none;
    }
    &__padding {
      padding-top: 0.05rem;
    }
  }

  &_printButton {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 4.8rem;
    max-height: 5.8rem;
    padding: 1.1rem 0 0.8rem 0;
    border-radius: .7rem;
    background: white;
    box-shadow: .1rem .3rem .6rem 0px #ccc;
    font-size: 1.6rem;
    &__bold {
      font-weight: $font-bold;
      color: $text-grey;
      margin-bottom: 0.5rem;
    }
    &__info {
      color: $text-grey-lighten;
      font-size: 1.1rem;
      margin: 0 1rem;
    }
  }
}