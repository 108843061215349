@import 'style/_variables.scss';

.InfoBox {
  &_wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 1fr;
  }

  &_img {
    align-self: baseline;
  }

  &_text {
    margin: 0;
    font-size: 1.4rem;
    text-align: left;
  }
}