@import 'style/_variables.scss';

.signUp {
  &_wrapper {
    color: white;
    position: relative;
  }
  &_container {
    justify-items: center;
    align-items: start;
    height: $height-without-appbar;
    grid-template-rows: repeat(2, max-content) auto;
  }
  &_info {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &_button {
    align-self: center;
  }
}