@import 'style/main.scss';

.give {

  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_tip {
    font-weight: $font-bold;
  }

  &_tipPrice {
    color: $bg-gradient-bottom-purpure;

    &__size22 {
      font-size: 2.2rem;
    }

    &__size18 {
      font-size: 1.8rem;
    }
  }

  &_titleTip {
    height: 40px;
    width: 100%;
    font-size: 2.6rem;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background: white;
  }

  &_subTitle {
    margin-top: .5rem;
    font-size: 1.4rem;
  }

  &_payment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4.4rem;
    text-align: center;
    height: 6rem;
  }

  &_paymentItem {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    background: white;
    border: 2px dotted white;
    border-radius: .6rem;
    height: 6rem;
    box-sizing: border-box;

    &__selected {
      border: 2px solid $bg-gradient-top-purpure;
    }
  }

  &_gpayButton {
    // Override Google Pay styles
    button {
      width: 100% !important;
      height: 100%;
      box-shadow: none !important;
    }
  }

  &_blikCode {
    input {
      &::placeholder { @include placeholder-blik; }
    }
  }

  &_paymentSectionButton {

    &__active {
      @extend .card__activeRotate;
      font-weight: $font-semiBold !important;
      border: none !important;
    }

    &__disabled {
      border: 2px solid $text-grey-disabled !important;
      
      > span {
        color: $text-grey-disabled !important;
        font-weight: $font-semiBold;
      }
    }
  }

  &_thankYouPage {
    > div {
      color: $text-grey;
      margin-top: -.5rem;
    }
  }

}