@import 'style/main.scss';

.tipPrice {
  color: $bg-gradient-bottom-purpure;

  &__size22 {
    font-size: 2.2rem;
  }

  &__size18 {
    font-size: 1.8rem;
  }
}