@import 'style/main.scss';

.tips {
  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_title {
    font-size: 2.4rem;
    margin-top: 0.9rem;
  }
  
  &_subTitle {
    margin-top: .5rem;
    font-size: 1.4rem;
  }

  &_period {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .7rem;
  }

  &_periodWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .8rem;
    margin: 2rem 0;
  }

  &_periodItem {
    display: grid;
    width: 100%;
    padding: 2.1rem 0;
    border-radius: .7rem;
    background: white;
    box-shadow: .1rem .3rem .6rem 0px #ccc;

    &__active {
      @extend .card__active;
      font-weight: $font-semiBold !important;
      border: none !important;
    }
  }

  &_periodItemTime {
    margin-bottom: .7rem;
    font-size: 1.2rem;
    color: $text-grey-lighten;
  }

  &_periodItemAmount {
    font-weight: $font-bold;
    font-size: 1.4rem;
  }

  &_dateList {
    font-size: 1.4rem;
  }

  &_dateTitle {
    font-weight: $font-bold;
  }

  &_dateTable {
    width: 100%;
  }
  
  &_dateTableValue {
    font-weight: $font-bold;
    text-align: right;
  }

  &_hourCellSingle {
    width: 9rem;
  }

  &_hourCellTipBox {
    width: 5rem;
  }

  &_nameCell{
    max-width: 13rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_dateTableRow {
    line-height: 2rem;
  }
}